import React from "react";
import useProductParams from "../../../../hooks/useProductParams";
import { getProductData } from "../../../../services/productService";
import { useQuery } from "@tanstack/react-query";
import { fetchResource, queryKeys } from "../../../../services/reactQuery/reactQueryService";
import Layout from "../../../Layouts/Layout";
import Container from "../../../../components/Container/Container";
import LegalbirdLoader from "../../../../components/ContentLoader/LegalbirdLoader";
import UploadFineNotice from "./UploadFineNotice";

export default function UploadFineNoticePage() {
  const { productId } = useProductParams();
  const productApiUri = getProductData("traffic", "apiUri");
  const { data: traffic, isLoading } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));

  if (isLoading) {
    return (
      <Layout>
        <Container sx={{ width: 250 }}>
          <LegalbirdLoader />
        </Container>
      </Layout>
    );
  }

  return (
    <Layout>
      <UploadFineNotice traffic={traffic} />
    </Layout>
  );
}
