import React from "react";
import { useLocation } from "react-router-dom";
import Error404Page from "../Error404/Error404Page";
import Layout from "../../../Layouts/Layout";
import MessageForm from "../../../../components/Messaging/MessageForm/MessageForm";
import { getProductData } from "../../../../services/productService";
import { useQuery } from "@tanstack/react-query";
import { fetchResource, queryKeys } from "../../../../services/reactQuery/reactQueryService";
import LegalbirdLoaderPage from "../../Loading/LegalbirdLoaderPage";

export default function MessageFormPage() {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const productName = urlSearchParams.get("product");
  const productId = urlSearchParams.get("productId");
  const parsedProductId = parseInt(productId || "0");
  const productApiUri = getProductData(productName, "apiUri");

  const { data: product, isLoading } = useQuery(queryKeys.item(productApiUri, parsedProductId), () => fetchResource(productApiUri, parsedProductId));

  if (isLoading) {
    return <LegalbirdLoaderPage />;
  }

  if (!product) {
    return <Error404Page />;
  }

  return (
    <Layout backgroundType={"serviceworld"}>
      <MessageForm product={product} />
    </Layout>
  );
}
