import React from "react";
import ContractLawDetailsSection from "../ContractLaw/ContractLawCaseDetails/ContractLawDetailsSection";
import SummaryBoxDivorce from "../../Form/Divorce/Done/SummaryBox";
import SummaryBoxSettlement from "../../Form/Settlement/Done/SummaryBox";
import CaseDetails from "../../../containers/Pages/Traffic/TrafficMyCasesPage/CaseDetails";
import { AbstractCase } from "../../../types/Entities/AbstractCase";

type ServiceWorldCaseDetailsProps = {
  product: AbstractCase;
};

const ServiceWorldCaseDetails = ({ product }: ServiceWorldCaseDetailsProps) => {
  if (!product.isCustomerDataCompleted) {
    return null;
  }

  switch (product.name) {
    case "alimony":
      return null;
    case "divorce":
      return <SummaryBoxDivorce product={product} />;
    case "death":
      return null;
    case "familyLawCase":
      return null;
    case "settlement":
      return <SummaryBoxSettlement product={product} />;
    case "traffic":
      return <CaseDetails caseItem={product} />;
    case "eventContract":
    case "membershipContract":
    case "otherContract":
    case "providerContract":
    case "purchaseContract":
    case "serviceContract":
    case "subscriptionContract":
    case "travelContract":
    case "vehicleContract":
    case "dataLeakContract":
      return <ContractLawDetailsSection contractLawCase={product} />;
    default:
      return null;
  }
};

export default ServiceWorldCaseDetails;
