import TextField from "@mui/material/TextField";
import ButtonLoading from "../Button/ButtonLoading";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import feedbackStyles from "./feedbackStyles";
import { getThirdPartyCustomerRatingPlatform } from "../../services/customerFeedbackService";
import { CustomerFeedback } from "../../types/Entities/CustomerFeedback";

type FeedbackProps = {
  feedbackEntity: CustomerFeedback;
  stars: number;
  saveFeedback: (feedback: Partial<CustomerFeedback>) => void;
};

export default function FeedbackComment({ feedbackEntity, stars, saveFeedback }: FeedbackProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState<string | undefined>(undefined);
  const { ratingPlatform, ratingPlatformLink } = getThirdPartyCustomerRatingPlatform(feedbackEntity.caseId);

  if (!!feedbackEntity.feedbackDateTime) {
    if (feedbackEntity.isFinalReview && feedbackEntity.stars === 5) {
      return (
        <>
          <Typography sx={{ color: "primary.main" }} variant={"h5"}>
            Helfen Sie jetzt auch anderen Kunden, sich zu orientieren!
          </Typography>
          <p>Über das Bewertungsportal {ratingPlatform} können Sie die Unterstützung durch Legalbird öffentlich sichtbar darstellen und bewerten.</p>
          <p>Dies dauert nur eine Minute und hilft anderen Rechtsuchenden bei der Auswahl der richtigen Kanzlei.</p>
          <Box sx={feedbackStyles.ctaButtonContainer}>
            <Button fullWidth={true} color="primary" variant="contained" component="a" href={ratingPlatformLink} target="_blank">
              Auf {ratingPlatform} bewerten
            </Button>
          </Box>
          <p>Wir bedanken uns im Voraus und unterstützen Sie in Zukunft jederzeit gerne wieder!</p>
        </>
      );
    }
    return (
      <>
        <p>Ihre Bewertung {!!feedbackEntity.comment ? "und Ihr Kommentar wurden" : "wurde"} gespeichert</p>
        <Button fullWidth color="primary" component={Link} to={"/servicewelt"}>
          Zu meiner Servicewelt
        </Button>
      </>
    );
  }

  const handleSubmit = () => {
    setIsLoading(true);

    const updatedFeedback: Partial<CustomerFeedback> = {
      caseId: feedbackEntity.caseId,
      isFinalReview: feedbackEntity.isFinalReview,
      source: feedbackEntity.source,
      hash: feedbackEntity.hash,
      comment: comment,
      stars: stars,
    };

    saveFeedback(updatedFeedback);

    setIsLoading(false);
  };

  return (
    <>
      {feedbackEntity.source !== "footer" ? (
        <Box sx={feedbackStyles.commentContainer}>
          <p>Bitte hinterlassen Sie uns noch einen kurzen Kommentar zu Ihrer Bewertung.</p>
          <TextField
            label="Ihr Kommentar"
            name="comment"
            value={comment}
            onChange={({ target }) => setComment(target.value)}
            multiline
            rows="4"
            maxRows="4"
            margin="normal"
            fullWidth
          />
        </Box>
      ) : (
        <p>Mit Ihrer Bewertung helfen Sie uns dabei, uns verbessern zu können.</p>
      )}
      <Box sx={feedbackStyles.ctaButtonContainer}>
        <ButtonLoading fullWidth={true} color="primary" variant="contained" onClick={handleSubmit} isLoading={isLoading} disabled={!stars}>
          Bewertung speichern
        </ButtonLoading>
      </Box>
      <Button fullWidth color="primary" component={Link} to={"/servicewelt"}>
        Zu meiner Servicewelt
      </Button>
    </>
  );
}
