import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconServiceNotAsAgreed = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 122.9 104.5">
      <g style={{ fill: getIconColor(active, theme), strokeWidth: 2, strokeLinecap: "round", strokeMiterlimit: 10 }}>
        <path d="M110.6,60.7l-19.2,9.5l-1.9,4.5c-3.9,6-10.1,7.5-17.8,7.5c-1.5,0-3.1,0.4-4.6,0.2c-4.4,0-4.2-5.7,0-5.5 c6.1,0.5,12.1,0.7,16.6-2.6c5.7-3.9,2.6-9.8-3.2-10.2H67.6c-7-4.4-14.1-8.2-21.1-9.5H29.8v36.9l15.6,8.1c10.6,6.2,21.2,6.4,31.8,0 C91.8,93,106,84.7,120,74.6C127,67.6,120.2,57.5,110.6,60.7z" />
        <rect y="51.2" width="24.8" height="44.2" />
      </g>
      <g style={{ stroke: getIconColor(active, theme) }}>
        <line x1="72.1" y1="54.7" x2="42.8" y2="16.2" style={{ display: "none", fill: "none" }} />
        <line
          x1="78"
          y1="54.7"
          x2="51.7"
          y2="24.8"
          style={{
            display: "none",
            fill: "none",
            strokeWidth: 2,
            strokeLinecap: "round",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1="85"
          y1="54.7"
          x2="73.4"
          y2="22"
          style={{
            display: "none",
            fill: "none",
            strokeWidth: 2,
            strokeLinecap: "round",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1="92.5"
          y1="54.7"
          x2="102.3"
          y2="23.6"
          style={{
            display: "none",
            fill: "none",
            strokeWidth: 2,
            strokeLinecap: "round",
            strokeMiterlimit: 10,
          }}
        />
        <line
          x1="101"
          y1="55.4"
          x2="119.1"
          y2="26.4"
          style={{
            display: "none",
            fill: "none",
            strokeWidth: 2,
            strokeLinecap: "round",
            strokeMiterlimit: 10,
          }}
        />
        <line x1="77.9" y1="55.5" x2="52.7" y2="33.1" style={{ fill: "none", strokeWidth: 2, strokeLinecap: "round", strokeMiterlimit: 10 }} />
        <line x1="84.5" y1="55.5" x2="71.9" y2="32.3" style={{ fill: "none", strokeWidth: 2, strokeLinecap: "round", strokeMiterlimit: 10 }} />
        <line x1="100.2" y1="56.5" x2="121.5" y2="34.3" style={{ fill: "none", strokeWidth: 2, strokeLinecap: "round", strokeMiterlimit: 10 }} />
        <line x1="93.1" y1="55.9" x2="100.9" y2="31.9" style={{ fill: "none", strokeWidth: 2, strokeLinecap: "round", strokeMiterlimit: 10 }} />
      </g>
    </SvgIcon>
  );
};

export default IconServiceNotAsAgreed;
