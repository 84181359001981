import React, { ReactElement } from "react";
import Layout from "../../../Layouts/Layout";
import { Container, SvgIconProps } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import iconsOverviewPageStyle from "./iconsOverviewPageStyle";
import { Navigate } from "react-router";

export default function IconsOverviewPage() {
  const iconFilePaths = require.context("../../../../assets/icons", true, /\.tsx$/);

  if (process.env.REACT_APP_DEPLOYMENT === "production") {
    return <Navigate to={"/"} />;
  }

  const getIconName = (iconFilePath: string) => {
    return iconFilePath.replace("./", "").replace(".tsx", "");
  };

  const copyToClipboard = (iconName: string) => {
    navigator.clipboard.writeText(iconName);
  };

  const generateAllSvgIconComponentList = () => {
    const icons: ReactElement<SvgIconProps>[] = [];

    iconFilePaths.keys().forEach((iconFilePath: string) => {
      // default is necessary to access the default export (the component) from the module
      const IconComponent = iconFilePaths(iconFilePath).default;
      const isToggleable = iconFilePath.includes("toggleable");

      const iconStates = isToggleable
        ? [
            { active: true, label: "Active" },
            { active: false, label: "InActive" },
          ]
        : [{ active: false, label: "" }];

      iconStates.forEach(({ active, label }) => {
        icons.push(
          <Grid item xs={2} sm={2} key={iconFilePath + (label ? `-${label}` : "")} sx={iconsOverviewPageStyle.grid}>
            <IconComponent sx={iconsOverviewPageStyle.icon} active={active} />
            <Typography sx={iconsOverviewPageStyle.typography} onClick={() => copyToClipboard(getIconName(iconFilePath))}>
              {getIconName(iconFilePath)} {label && `- (${label})`}
            </Typography>
          </Grid>
        );
      });
    });

    return icons;
  };

  return (
    <Layout>
      <Container>
        <Grid container spacing={2} sx={iconsOverviewPageStyle.container}>
          {generateAllSvgIconComponentList()}
        </Grid>
      </Container>
    </Layout>
  );
}
