import React from "react";
import DivorceRealCostsBox from "./DivorceRealCostsBox";
import DivorceCostCalculator from "./DivorceCostCalculator";
import { STATUS_DATA_COMPLETED } from "../../../../services/divorce/leadStatus";
import ContentBox from "../../../Box/ContentBox/ContentBox";
import costIcon from "../../../../assets/icons/serviceOverview/IconCosts";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";

type DivorceCostBoxProps = {
  divorce: AbstractCase;
};

const DivorceCostBox = ({ divorce }: DivorceCostBoxProps) => {
  if (divorce.isInitialProxy || divorce.leadStatus < STATUS_DATA_COMPLETED) {
    return (
      <ContentBox icon={costIcon} headline={"Kostenrechner"} alignContent={"stretch"} loading={false}>
        <DivorceCostCalculator />
      </ContentBox>
    );
  }

  return (
    <ContentBox icon={costIcon} headline={"Kostenübersicht"} alignContent={"stretch"} loading={!divorce}>
      <DivorceRealCostsBox divorce={divorce} />
    </ContentBox>
  );
};

export default DivorceCostBox;
