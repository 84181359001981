import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconDocumentPv = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59">
        <g>
          <path
            style={{ fill: "none" }}
            d="M124.267,34.752c0.543,5.068,4.877,8.642,10.708,8.642h29.766c6.239,0,10.767-4.091,10.767-9.728
			v-2.199c0-5.637-4.528-9.728-10.767-9.728h-29.766c-5.825,0-10.156,3.567-10.706,8.629c0.396,0.638,0.636,1.384,0.636,2.19
			C124.905,33.366,124.665,34.113,124.267,34.752z"
          />
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M241.801,206.651c-2.302,0-4.168,1.866-4.168,4.169v60.713c0,2.37-1.929,4.298-4.3,4.298H66.67
				c-2.372,0-4.302-1.928-4.302-4.298V93.789c0-2.303-1.866-4.169-4.168-4.169s-4.168,1.866-4.168,4.169v177.744
				c0,6.967,5.67,12.636,12.639,12.636h166.662c6.968,0,12.637-5.669,12.637-12.636V210.82
				C245.969,208.518,244.103,206.651,241.801,206.651z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M233.332,28.39h-53.479c-0.416,0-0.81,0.079-1.189,0.192c-1.345-6.046-6.88-10.321-13.924-10.321
				h-29.766c-6.982,0-12.477,4.202-13.883,10.165c-0.119-0.011-0.233-0.036-0.355-0.036H66.67c-6.969,0-12.639,5.67-12.639,12.64
				V78.33c0,2.303,1.866,4.169,4.168,4.169s4.168-1.866,4.168-4.169V41.029c0-2.372,1.93-4.302,4.302-4.302h54.066
				c0.12,0,0.233-0.025,0.351-0.035c1.401,5.97,6.899,10.18,13.887,10.18h29.766c7.05,0,12.589-4.282,13.928-10.336
				c0.378,0.112,0.771,0.191,1.185,0.191h53.479c2.371,0,4.3,1.93,4.3,4.302v153.934c0,2.303,1.866,4.169,4.168,4.169
				s4.168-1.866,4.168-4.169V41.029C245.969,34.06,240.3,28.39,233.332,28.39z M175.507,33.666c0,5.637-4.528,9.728-10.767,9.728
				h-29.766c-5.83,0-10.164-3.573-10.708-8.642c0.398-0.639,0.638-1.386,0.638-2.193c0-0.807-0.239-1.553-0.636-2.19
				c0.549-5.062,4.88-8.629,10.706-8.629h29.766c6.239,0,10.767,4.091,10.767,9.728V33.666z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M87.734,193.167h121.283c0.96,0,1.739-0.779,1.739-1.739s-0.778-1.739-1.739-1.739H87.734
				c-0.96,0-1.739,0.779-1.739,1.739S86.774,193.167,87.734,193.167z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M87.734,175.372h121.283c0.96,0,1.739-0.779,1.739-1.739s-0.778-1.739-1.739-1.739H87.734
				c-0.96,0-1.739,0.779-1.739,1.739S86.774,175.372,87.734,175.372z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M197.975,227.397H98.896c-0.96,0-1.739,0.779-1.739,1.739s0.778,1.739,1.739,1.739h99.078
				c0.96,0,1.739-0.779,1.739-1.739S198.935,227.397,197.975,227.397z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M197.975,208.208H98.896c-0.96,0-1.739,0.779-1.739,1.739s0.778,1.739,1.739,1.739h99.078
				c0.96,0,1.739-0.779,1.739-1.739S198.935,208.208,197.975,208.208z"
            />
          </g>
          <polygon
            style={{ fill: "#137F7B" }}
            points="156.875,97.951 156.875,75.215 143.123,75.215 143.123,97.951 120.387,97.951
			120.387,111.705 143.123,111.705 143.123,134.439 156.875,134.439 156.875,111.705 179.613,111.705 179.613,97.951 		"
          />
        </g>
      </g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconDocumentPv;
