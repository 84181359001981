/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import { scrollToAnchor } from "../../../../theme/commonStyle";

const noWinNoFeeSectionStyle = {
  noWinNoFeeSection: {
    textAlign: "center",
  },
  h3: {
    fontSize: "1.25rem",
    fontWeight: 500,
  },
  subHeadline: {
    paddingTop: "2rem",
    color: "primary.main",
  },
  costsSecureIcon: {
    marginTop: "2rem",
    width: "150px",
    height: "auto",
  },
  scrollToAnchor,
};

export default noWinNoFeeSectionStyle;
