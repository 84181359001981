/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import { scrollToAnchor } from "../../../../theme/commonStyle";
import { Theme } from "@mui/material";

const howItWorksSectionStyle = {
  background: {
    background: "linear-gradient(to bottom, #ffffff 25%, #acd2d0 25%, #acd2d0 85%, transparent 0)",
  },
  centered: {
    textAlign: "center",
  },
  paper: {
    padding: "2rem",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  advantageContainer: {
    marginTop: "40px",
  },
  icon: (theme: Theme) => ({
    width: "128px",
    height: "128px",

    [theme.breakpoints.only("xs")]: {
      maxWidth: "90px",
      maxHeight: "90px",
    },
  }),
  gridItemReversed: (theme: Theme) => ({
    [theme.breakpoints.only("xs")]: {
      order: -1,
    },
  }),
  scrollToAnchor,
};

export default howItWorksSectionStyle;
