import React, { useState } from "react";
import useProductParams from "../../../../hooks/useProductParams";
import { getCaseLink, getProductData, getProductNameBy } from "../../../../services/productService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, fetchResource, queryKeys, updateResource } from "../../../../services/reactQuery/reactQueryService";
import LegalbirdLoaderPage from "../../Loading/LegalbirdLoaderPage";
import { FormProvider, useForm } from "../../../../provider/Form/FormProvider";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";
import Group from "../../../../components/Wizard/Group";
import { InquiryToCustomerStatus } from "../../../../types/Entities/InquiryToCustomer";
import { Navigate } from "react-router-dom";
import { FormDataTypes } from "../../../../types/FormDataTypes";
import ButtonLoading from "../../../../components/Button/ButtonLoading";
import { requiredValidator } from "../../../../services/validationRules";
import Layout from "../../../Layouts/Layout";
import Container from "../../../../components/Container/Container";
import IconCheck from "../../../../assets/icons/common/toggleable/IconCheck";
import IconClose from "../../../../assets/icons/common/toggleable/IconClose";
import IconQuestionmark from "../../../../assets/icons/common/toggleable/IconQuestionmark";
import HighlightBox from "../../../../components/Box/HighlightBox/HighlightBox";

export default function NextInstanceDecisionPage() {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { productName, productId } = useProductParams();
  const productClassName = getProductNameBy("urlPartShort", productName);
  const productApiUri = getProductData(productClassName, "apiUri");
  const { data: product, isLoading: isLoadingProduct } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));

  const inquiryFilters = {
    product: product?.name,
    identifier: "nextInstanceDecisionInquiry",
    productId: productId,
    "status[0]": InquiryToCustomerStatus.STATUS_OPEN,
    "status[1]": InquiryToCustomerStatus.STATUS_OVERDUE,
    "status[2]": InquiryToCustomerStatus.STATUS_IN_PROGRESS,
  };
  const inquiryApiUri = "/inquiry_to_customers";
  const { data: inquiries, isLoading: isLoadingInquiries } = useQuery(
    queryKeys.collection(inquiryApiUri, inquiryFilters),
    () => fetchCollection(inquiryApiUri, inquiryFilters),
    {
      enabled: !!product,
    }
  );

  const queryClient = useQueryClient();
  const updateMutation = useMutation(updateResource, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKeys.item(productApiUri, productId));
      await queryClient.invalidateQueries(queryKeys.collection(inquiryApiUri, inquiryFilters));
    },
  });

  if (isLoadingProduct || isLoadingInquiries) {
    return <LegalbirdLoaderPage />;
  }

  if (!inquiries?.[0]) {
    return <Navigate to={getCaseLink(product)} />;
  }

  const onSubmit = async ({ values }: FormDataTypes["values"]) => {
    setIsSubmitting(true);
    await updateMutation.mutateAsync({
      uri: getProductData(product.name, "apiUri"),
      id: product.id,
      data: {
        nextInstanceDecisionClient: values.nextInstanceDecisionClient,
      },
    });
    setIsSubmitting(false);
  };

  return (
    <Layout>
      <Container>
        <FormProvider initialValues={{ nextInstanceDecisionClient: "" }} submitAction={onSubmit}>
          <NextInstanceDecisionForm product={product} isSubmitting={isSubmitting} />
        </FormProvider>
      </Container>
    </Layout>
  );
}

function NextInstanceDecisionForm({ product, isSubmitting }: { product: AbstractCase; isSubmitting: boolean }) {
  const { handleSubmit } = useForm();

  const groupConfig = getGroupConfig(product.nextInstanceRecommendation);

  return (
    <Container>
      <Paper sx={{ padding: "2rem" }}>
        <Typography variant={"h4"} sx={{ textAlign: "center" }}>
          Ihre Entscheidung über das Einlegen von Rechtsmitteln
        </Typography>
        <form onSubmit={handleSubmit}>
          <Group
            question={groupConfig.question}
            type={"radio"}
            name={"nextInstanceDecisionClient"}
            options={groupConfig.options}
            validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Optionen aus")]}
          />
          <Stack direction={"row"} justifyContent={"center"} sx={{margin: "2rem"}}>
            <ButtonLoading sx={{ width: "350px" }} variant="contained" color="primary" type="submit" fullWidth={false} isLoading={isSubmitting}>
              Rückmeldung geben
            </ButtonLoading>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
}

const getGroupConfig = (nextInstanceRecommendation?: "appeal" | "noAppeal") => {
  switch (true) {
    case !nextInstanceRecommendation:
      return {
        question: (
          <Box sx={{ marginBottom: "2rem" }}>
            <Stack spacing={3}>
              <Typography paragraph>
                Wie Sie dem vorliegenden Gerichtsurteil entnehmen können, hat das Gericht nicht zu unseren Gunsten entschieden. Das Urteil ist noch nicht
                rechtskräftig - es kann Berufung eingelegt werden. Gerne bieten wir an, das Urteil näher zu prüfen und Ihnen eine Empfehlung auszusprechen, ob
                es hinreichende Erfolgsaussichten für ein Berufungsverfahren gibt. Wenn Sie sich bereits sicher sind, dass Sie Rechtsmittel gegen das Urteil
                einlegen möchten, können Sie dies ebenfalls angeben.{" "}
              </Typography>
              <HighlightBox headline={"Wichtig für Sie:"} textAlign={"center"}>
                <Typography paragraph>
                  Es fallen für Sie keine Kosten für die Prüfung des Gerichtsurteils an. Diese werden von Ihrer Rechtsschutzversicherung übernommen.
                </Typography>
              </HighlightBox>
            </Stack>
          </Box>
        ),
        options: [
          {
            labelText: "Ich möchte gegen das Urteil in Berufung gehen",
            labelIcon: <IconCheck />,
            labelIconActive: <IconCheck active />,
            value: "appeal",
          },
          {
            labelText: "Ich möchte das Urteil durch Legalbird überprüfen lassen und anhand dieser Einschätzung über das weitere Vorgehen entscheiden",
            labelIcon: <IconQuestionmark />,
            labelIconActive: <IconQuestionmark active />,
            value: "checkJudgement",
          },
          {
            labelText: "Ich möchte das Urteil akzeptieren und keine Rechtsmittel einlegen",
            labelIcon: <IconClose />,
            labelIconActive: <IconClose active />,
            value: "noAppeal",
          },
        ],
      };
    case nextInstanceRecommendation === "appeal":
      return {
        question: (
          <>
            <Typography paragraph>
              Sie haben uns beauftragt, das Gerichtsurteil von Ihrem Fall auf die Erfolgsaussichten einer Berufung zu überprüfen. Nach umfänglicher Prüfung des
              erstinstanzlichen Urteils raten wir Ihnen, Berufung einzulegen.
            </Typography>
            <Typography paragraph>Bitte geben Sie uns eine Rückmeldung, ob Sie Rechtsmittel gegen das Urteil einlegen möchten. </Typography>
          </>
        ),
        options: [
          {
            labelText: "Ich möchte Berufung gegen das Gerichtsurteil einlegen",
            labelIcon: <IconCheck />,
            labelIconActive: <IconCheck active />,
            value: "appeal",
          },
          {
            labelText: "Ich möchte trotz Ihrer Einschätzung nicht gegen das Gerichtsurteil in Berufung gehen",
            labelIcon: <IconClose />,
            labelIconActive: <IconClose active />,
            value: "noAppeal",
          },
        ],
      };
    case nextInstanceRecommendation === "noAppeal":
      return {
        question: (
          <>
            <Typography paragraph>
              Sie haben uns beauftragt, das erstinstanzliche Urteil hinsichtlich der Erfolgsaussichten der Berufung zu überprüfen. Nach umfänglicher Prüfung
              sind wir zu dem Ergebnis gekommen, dass keine ausreichenden Erfolgsaussichten für eine Berufung bestehen. Wir können Ihnen daher die Einlegung der
              Berufung nicht empfehlen.{" "}
            </Typography>
            <Typography paragraph>
              Dennoch liegt die endgültige Entscheidung selbstverständlich bei Ihnen. Bitte geben Sie uns zeitnah Ihre Rückmeldung.
            </Typography>
          </>
        ),
        options: [
          {
            labelText: "Ich möchte trotz Ihrer Einschätzung Berufung gegen das Gerichtsurteil einlegen",
            labelIcon: <IconCheck />,
            labelIconActive: <IconCheck active />,
            value: "appeal",
          },
          {
            labelText: "Ich möchte nicht gegen das Gerichtsurteil in Berufung gehen",
            labelIcon: <IconClose />,
            labelIconActive: <IconClose active />,
            value: "noAppeal",
          },
        ],
      };
    default:
      return {
        question: "",
        options: [],
      };
  }
};
