import React from "react";
import Layout from "../../../Layouts/Layout";
import { Box, Container } from "@mui/material";
import { Navigate } from "react-router";
import Typography from "@mui/material/Typography";
import Colors from "../../../../components/ThemeOverview/Colors";
import TypographyVariants from "../../../../components/ThemeOverview/TypographyVariants";

export default function ThemeOverviewPage() {
  if (process.env.REACT_APP_DEPLOYMENT === "production") {
    return <Navigate to={"/"} />;
  }

  return (
    <Layout>
      <Container>
        <Box p={2}>
          <Typography variant="h1" gutterBottom>
            Theme Overview
          </Typography>

          <Colors />

          <TypographyVariants />
        </Box>
      </Container>
    </Layout>
  );
}
