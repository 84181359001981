/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import { Theme } from "@mui/material";

const familyLawTeaserCardSectionStyle = {
  container: {
    textAlign: "center",
  },
  heading: {
    marginBottom: "50px",
  },
  cardContainer: (theme: Theme) => ({
    [theme.breakpoints.down("lg")]: {
      width: "532px",
      margin: "auto",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  }),
};

export default familyLawTeaserCardSectionStyle;
