import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconDocumentRegister = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13">
        <g>
          <path
            style={{ fill: "none" }}
            d="M132.505,270.619c0.192-0.492,0.102-0.752-0.025-0.959c-0.146-0.24-0.311-0.439-0.732-0.439
			c-0.11,0-0.238,0.014-0.388,0.043c-2.487,0.502-5.975,4.377-6.287,6.432c-0.036,0.244,0.04,0.469,0.173,0.666
			C128.146,274.932,131.73,272.604,132.505,270.619z"
          />
          <path
            style={{ fill: "none" }}
            d="M106.298,272.916c9.803-4.615,12.623-8.334,13.416-10.281c0.378-0.928,0.43-1.77,0.154-2.5
			c-0.197-0.098-0.523-0.148-0.948-0.148c-1.953,0-6.004,1.072-9.372,3.65c-2.331,1.785-4.84,4.703-3.555,8.477
			C106.088,272.391,106.189,272.658,106.298,272.916z"
          />
          <path
            style={{ fill: "none" }}
            d="M139.323,134.59c-0.996,0-1.97-0.385-2.703-1.1l-9.636-9.389v14.686h13.604l-1.161-4.203
			C139.393,134.584,139.358,134.59,139.323,134.59z"
          />
          <path
            style={{ fill: "#FFFFFF" }}
            d="M205.625,167.914l-0.966-3.467l-3.068-11.006l-0.507-1.818l-0.459-1.648l-6.475-23.229
			l-1.928-6.916l-7.042,1.984l-10.9,11.189c-0.966,0.992-2.387,1.396-3.73,1.053l-15.138-3.85l-8.492,2.395l15.571,56.375
			l14.857-24.785c1.076-1.793,3.384-2.412,5.211-1.391l11.592,6.469l6.935,3.869l7.103,3.963L205.625,167.914z"
          />
          <g>
            <polygon
              style={{ fill: "#137F7B" }}
              points="126.983,138.787 126.983,124.102 125.432,122.59 115.544,120.078 115.544,138.787
				96.636,138.787 96.636,150.227 115.544,150.227 115.544,169.137 126.983,169.137 126.983,150.227 143.748,150.227
				140.588,138.787 			"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M206.642,70.787c-0.185-0.654-0.194-1.344-0.027-2.004l3.553-13.979l-10.33-10.066
				c-0.487-0.473-0.84-1.068-1.025-1.723l-1.855-6.578l-1.956-6.934l-0.104-0.369l-13.979-3.551c-0.659-0.168-1.263-0.506-1.75-0.98
				l-10.326-10.064l-13.882,3.914c-0.655,0.184-1.347,0.193-2.005,0.025l-13.977-3.551l-10.064,10.33
				c-0.474,0.486-1.068,0.84-1.723,1.023l-11.427,3.223l-2.455,0.693l-1.586,6.24l-1.966,7.736c-0.167,0.66-0.506,1.264-0.98,1.75
				l-2.923,3l-6.935,7.117l-0.207,0.213l0.207,0.732l3.709,13.148c0.185,0.654,0.194,1.346,0.026,2.006l-1.028,4.045l-1.209,4.754
				l-1.317,5.18l1.802,1.754l8.527,8.307c0.487,0.475,0.841,1.07,1.025,1.725l3.916,13.881l13.976,3.551
				c0.659,0.168,1.263,0.506,1.749,0.98l10.33,10.064l13.882-3.914c0.655-0.184,1.346-0.193,2.006-0.025l6.507,1.654l7.469,1.9
				l5.381-5.523l4.683-4.809c0.475-0.486,1.069-0.84,1.724-1.025l11.789-3.322l0.007-0.002l0.276-0.078l1.81-0.512l3.549-13.977
				c0.167-0.66,0.506-1.264,0.98-1.75l0.595-0.611l9.47-9.717L206.642,70.787z"
            />
          </g>
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M172.088,216.533c0-0.957-0.776-1.732-1.733-1.732H69.478c-0.958,0-1.733,0.775-1.733,1.732
				s0.776,1.734,1.733,1.734h100.877C171.312,218.268,172.088,217.49,172.088,216.533z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M162.9,247.898c0-0.955-0.776-1.732-1.733-1.732H78.763c-0.958,0-1.733,0.777-1.733,1.732
				c0,0.957,0.776,1.734,1.733,1.734h82.404C162.125,249.633,162.9,248.855,162.9,247.898z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M161.167,230.205H78.763c-0.958,0-1.733,0.775-1.733,1.732s0.776,1.734,1.733,1.734h82.404
				c0.958,0,1.733-0.777,1.733-1.734S162.125,230.205,161.167,230.205z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M255.083,174.018c1.915,0,3.467-1.551,3.467-3.467V40.008c0-5.791-4.713-10.504-10.506-10.504
				h-44.992l-1.305-4.625c-0.376-1.332-1.434-2.363-2.774-2.703l-15.141-3.846l-11.184-10.9c-0.992-0.967-2.423-1.332-3.755-0.955
				l-15.036,4.238l-15.138-3.846c-1.341-0.34-2.763,0.061-3.729,1.051l-10.901,11.188l-15.036,4.24
				c-1.332,0.375-2.362,1.434-2.703,2.775l-1.078,4.242l-2.77,10.896l-3.583,3.68l-7.317,7.51c-0.966,0.992-1.33,2.424-0.954,3.756
				l4.241,15.033l-2.467,9.701H51.956c-5.793,0-10.506,4.713-10.506,10.504v31.02c0,1.914,1.552,3.467,3.467,3.467
				s3.467-1.553,3.467-3.467v-31.02c0-1.969,1.602-3.57,3.572-3.57h39.003c0.118,0.836,0.507,1.627,1.132,2.236l11.188,10.896
				l4.242,15.035c0.376,1.332,1.434,2.361,2.774,2.703l5.249,1.334l9.888,2.512l1.552,1.512l9.636,9.389
				c0.733,0.715,1.708,1.1,2.703,1.1c0.035,0,0.07-0.006,0.104-0.006l1.161,4.203l3.16,11.439l13.681,49.531
				c0.023,0.084,0.059,0.162,0.087,0.242H69.478c-0.958,0-1.733,0.777-1.733,1.734s0.776,1.732,1.733,1.732h100.877
				c0.958,0,1.733-0.775,1.733-1.732S171.312,200,170.354,200h-5.438l17.145-28.604l12.088,6.744v60.627v44.398
				c0,1.969-1.603,3.57-3.574,3.57H51.956c-1.97,0-3.572-1.602-3.572-3.57V135.32c0-1.914-1.552-3.467-3.467-3.467
				s-3.467,1.553-3.467,3.467v147.846c0,5.793,4.713,10.504,10.506,10.504h138.619c5.794,0,10.508-4.711,10.508-10.504v-40.932
				h46.96c5.793,0,10.506-4.711,10.506-10.504v-48.314c0-1.914-1.552-3.467-3.467-3.467s-3.467,1.553-3.467,3.467v48.314
				c0,1.969-1.602,3.57-3.572,3.57h-46.96v-11.188c0.573-0.518,1.104-1.01,1.576-1.449c0.204-0.189,0.42-0.391,0.636-0.59
				c-0.008,1.193,0.149,2.326,1.222,2.973c1.356,0.816,2.979,0.014,4.526-0.879c0.829-0.479,1.113-1.539,0.634-2.367
				c-0.478-0.83-1.538-1.113-2.368-0.637c-0.199,0.115-0.375,0.213-0.531,0.297c0.001-0.018,0.001-0.033,0.001-0.051
				c0.015-0.393,0.03-0.82,0.03-1.277c0-1.367-0.464-2.262-1.38-2.66c-1.368-0.596-2.519,0.252-4.347,1.926v-22.514h18.033
				c0.958,0,1.733-0.775,1.733-1.732c0-0.959-0.776-1.734-1.733-1.734h-18.033v-11.01h0.713l10.729,5.986
				c1.365,0.762,3.055,0.629,4.284-0.338c1.229-0.967,1.755-2.578,1.335-4.084l-0.436-1.564h1.407c0.958,0,1.733-0.775,1.733-1.732
				s-0.776-1.732-1.733-1.732h-2.374l-3.074-11.029h14.145c0.958,0,1.733-0.775,1.733-1.732s-0.776-1.734-1.733-1.734h-15.111
				l-3.068-11.006h18.179c0.958,0,1.733-0.777,1.733-1.734s-0.776-1.732-1.733-1.732h-19.146l-7.584-27.207v-5.561
				c0.901-0.514,1.573-1.375,1.834-2.406l3.844-15.139l10.902-11.186c0.966-0.992,1.33-2.424,0.954-3.756l-4.237-15.035
				l3.848-15.141c0.341-1.342-0.06-2.764-1.052-3.729l-11.188-10.902l-0.98-3.477h43.036c1.97,0,3.572,1.602,3.572,3.57v130.543
				C251.616,172.467,253.168,174.018,255.083,174.018z M198.813,43.016c0.185,0.654,0.538,1.25,1.025,1.723l10.33,10.066
				l-3.553,13.979c-0.167,0.66-0.158,1.35,0.027,2.004l3.912,13.883l-9.47,9.717l-0.595,0.611c-0.475,0.486-0.813,1.09-0.98,1.75
				l-3.549,13.977l-1.81,0.512l-0.276,0.078l-0.007,0.002l-11.789,3.322c-0.655,0.186-1.25,0.539-1.724,1.025l-4.683,4.809
				l-5.381,5.523l-7.469-1.9l-6.507-1.654c-0.66-0.168-1.352-0.158-2.006,0.025l-13.882,3.914l-10.33-10.064
				c-0.486-0.475-1.09-0.813-1.749-0.98l-13.976-3.551l-3.916-13.881c-0.185-0.654-0.538-1.25-1.025-1.725l-8.527-8.307L99.1,86.119
				l1.317-5.18l1.209-4.754l1.028-4.045c0.167-0.66,0.158-1.352-0.026-2.006l-3.709-13.148l-0.207-0.732l0.207-0.213l6.935-7.117
				l2.923-3c0.474-0.486,0.813-1.09,0.98-1.75l1.966-7.736l1.586-6.24l2.455-0.693l11.427-3.223
				c0.655-0.184,1.249-0.537,1.723-1.023l10.064-10.33l13.977,3.551c0.659,0.168,1.351,0.158,2.005-0.025l13.882-3.914
				l10.326,10.064c0.487,0.475,1.091,0.813,1.75,0.98l13.979,3.551l0.104,0.369l1.956,6.934L198.813,43.016z M204.659,164.447
				l0.966,3.467l2.561,9.188l-7.103-3.963l-6.935-3.869l-11.592-6.469c-1.827-1.021-4.135-0.402-5.211,1.391l-14.857,24.785
				l-15.571-56.375l8.492-2.395l15.138,3.85c1.343,0.344,2.765-0.061,3.73-1.053l10.9-11.189l7.042-1.984l1.928,6.916l6.475,23.229
				l0.459,1.648l0.507,1.818L204.659,164.447z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M100.323,277.354c0.359,0.887,1.369,1.318,2.257,0.959c0.793-0.32,1.566-0.643,2.315-0.965
				c2.435,3.105,6.149,4.713,10.846,4.713c2.286,0,4.805-0.381,7.521-1.154c0.36-0.104,0.902-0.297,1.564-0.57
				c3.043,1.48,7.966,1.471,11.607-1.477c1.532-1.242,2.839-2.457,3.889-3.436c0.197-0.184,0.405-0.377,0.612-0.568
				c-0.006,1.189,0.153,2.318,1.222,2.963c1.357,0.816,2.983,0.014,4.532-0.881c0.83-0.477,1.115-1.537,0.637-2.367
				c-0.478-0.828-1.538-1.115-2.367-0.637c-0.203,0.117-0.382,0.217-0.54,0.301c0-0.02,0.001-0.037,0.002-0.057
				c0.014-0.391,0.028-0.818,0.028-1.275c0-1.363-0.463-2.256-1.377-2.654c-1.553-0.678-2.816,0.502-5.111,2.637
				c-1.011,0.941-2.27,2.113-3.708,3.277c-1.348,1.092-2.999,1.611-4.552,1.684c2.6-1.598,5.144-3.684,6.035-5.967
				c0.552-1.414,0.449-2.807-0.296-4.027c-1.02-1.668-2.756-2.391-4.765-1.986c-4.047,0.816-8.517,5.938-9.028,9.313
				c-0.131,0.863,0,1.707,0.362,2.479c-4.715,1.299-10.387,1.76-13.817-1.799c8.143-3.885,13.089-7.881,14.733-11.914
				c0.757-1.857,0.78-3.701,0.066-5.33c-1.298-2.961-6.22-2.279-10.04-0.793c-6.81,2.646-12.616,8.439-10.24,15.41
				c0.129,0.379,0.269,0.746,0.42,1.1c-0.596,0.254-1.211,0.51-1.85,0.768C100.394,275.455,99.965,276.465,100.323,277.354z
				 M131.36,269.264c0.149-0.029,0.277-0.043,0.388-0.043c0.422,0,0.586,0.199,0.732,0.439c0.126,0.207,0.217,0.467,0.025,0.959
				c-0.775,1.984-4.36,4.313-7.258,5.742c-0.133-0.197-0.209-0.422-0.173-0.666C125.386,273.641,128.873,269.766,131.36,269.264z
				 M109.548,263.637c3.368-2.578,7.419-3.65,9.372-3.65c0.425,0,0.751,0.051,0.948,0.148c0.276,0.73,0.224,1.572-0.154,2.5
				c-0.793,1.947-3.613,5.666-13.416,10.281c-0.108-0.258-0.21-0.525-0.305-0.803C104.708,268.34,107.217,265.422,109.548,263.637z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconDocumentRegister;
