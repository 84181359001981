import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconSettlement = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60">
        <g>
          <rect x="97.547" y="109.471" style={{ fill: "none" }} width="43.181" height="9.164" />
          <rect x="158.807" y="109.471" style={{ fill: "none" }} width="43.349" height="9.164" />
          <path
            style={{ fill: "none" }}
            d="M140.728,104.201c0-0.83,0.672-1.502,1.501-1.502h15.077c0.829,0,1.501,0.672,1.501,1.502v2.268
			h43.349V90.666c0-2.006-1.632-3.639-3.639-3.639h-97.33c-2.007,0-3.639,1.633-3.639,3.639v15.803h43.181V104.201z"
          />
          <g>
            <path
              style={{ fill: "#137F7B" }}
              d="M164.735,20.885h-29.764c-6.219,0-10.733,4.076-10.733,9.693v2.199
				c0,5.615,4.514,9.691,10.733,9.691h29.764c6.219,0,10.733-4.076,10.733-9.691v-1.082v-0.029v-1.088
				C175.469,24.961,170.955,20.885,164.735,20.885z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M167.052,70.061h-34.594c-1.061,0-1.924,0.863-1.924,1.926v2.43h38.442v-2.43
				C168.976,70.924,168.113,70.061,167.052,70.061z"
            />
            <polygon
              style={{ fill: "#137F7B" }}
              points="143.731,107.971 143.731,120.137 143.731,121.893 155.804,121.893 155.804,120.137
				155.804,107.971 155.804,105.701 143.731,105.701 			"
            />
          </g>
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M241.792,210.885c-1.958,0-3.545,1.586-3.545,3.545v56.221c0,2.711-2.206,4.918-4.917,4.918
				H66.663c-2.711,0-4.917-2.207-4.917-4.918V95.549c0-1.959-1.587-3.545-3.545-3.545c-1.958,0-3.545,1.586-3.545,3.545V270.65
				c0,6.621,5.387,12.008,12.008,12.008h166.666c6.621,0,12.008-5.387,12.008-12.008V214.43
				C245.337,212.471,243.75,210.885,241.792,210.885z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M233.327,28.137h-54.314c-0.079,0-0.152,0.018-0.229,0.023c-1.159-6.311-6.805-10.82-14.048-10.82
				h-29.764c-7.243,0-12.889,4.512-14.048,10.82c-0.08-0.006-0.156-0.023-0.237-0.023H66.655c-6.617,0-12,5.383-12,12v39.947
				c0,1.957,1.587,3.545,3.545,3.545c1.958,0,3.545-1.588,3.545-3.545V40.137c0-2.707,2.202-4.91,4.91-4.91h54.031
				c0.082,0,0.158-0.02,0.238-0.023c1.162,6.305,6.806,10.811,14.047,10.811h29.764c7.24,0,12.884-4.506,14.047-10.811
				c0.078,0.006,0.151,0.023,0.23,0.023h54.314c2.717,0,4.927,2.203,4.927,4.91v156.389c0,1.957,1.588,3.545,3.545,3.545
				c1.958,0,3.545-1.588,3.545-3.545V40.137C245.345,33.52,239.954,28.137,233.327,28.137z M164.735,42.469h-29.764
				c-6.219,0-10.733-4.076-10.733-9.691v-2.199c0-5.617,4.514-9.693,10.733-9.693h29.764c6.219,0,10.733,4.076,10.733,9.693v1.088
				v0.029v1.082C175.469,38.393,170.955,42.469,164.735,42.469z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M87.729,210.701h121.284c0.979,0,1.772-0.793,1.772-1.771s-0.793-1.773-1.772-1.773H87.729
				c-0.979,0-1.772,0.795-1.772,1.773S86.75,210.701,87.729,210.701z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M85.957,189.74c0,0.979,0.793,1.771,1.772,1.771h121.284c0.979,0,1.772-0.793,1.772-1.771
				s-0.793-1.773-1.772-1.773H87.729C86.75,187.967,85.957,188.762,85.957,189.74z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M197.968,245.529H98.894c-0.979,0-1.772,0.795-1.772,1.773s0.793,1.771,1.772,1.771h99.074
				c0.979,0,1.772-0.793,1.772-1.771S198.947,245.529,197.968,245.529z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M197.968,226.342H98.894c-0.979,0-1.772,0.795-1.772,1.773s0.793,1.771,1.772,1.771h99.074
				c0.979,0,1.772-0.793,1.772-1.771S198.947,226.342,197.968,226.342z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M202.156,126.098v26.082c0,2.008-1.632,3.641-3.639,3.641h-74.661
				c-0.829,0-1.501,0.672-1.501,1.502c0,0.828,0.672,1.5,1.501,1.5h74.661c3.663,0,6.642-2.98,6.642-6.643v-26.082
				c0-0.83-0.672-1.502-1.501-1.502S202.156,125.268,202.156,126.098z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M94.544,90.666v17.305v12.166v32.043c0,3.662,2.979,6.643,6.642,6.643h16.23
				c0.829,0,1.501-0.672,1.501-1.5c0-0.83-0.672-1.502-1.501-1.502h-16.23c-2.007,0-3.639-1.633-3.639-3.641v-30.543h43.181v1.758
				c0,0.828,0.672,1.5,1.501,1.5h15.077c0.829,0,1.501-0.672,1.501-1.5v-1.758h46.352v-13.666V90.666
				c0-3.662-2.979-6.641-6.642-6.641h-97.33C97.524,84.025,94.544,87.004,94.544,90.666z M97.547,118.635v-9.164h43.181v9.164
				H97.547z M155.804,107.971v12.166v1.756h-12.073v-1.756v-12.166v-2.27h12.073V107.971z M202.156,118.635h-43.349v-9.164h43.349
				V118.635z M202.156,90.666v15.803h-43.349v-2.268c0-0.83-0.672-1.502-1.501-1.502h-15.077c-0.829,0-1.501,0.672-1.501,1.502
				v2.268H97.547V90.666c0-2.006,1.632-3.639,3.639-3.639h97.33C200.523,87.027,202.156,88.66,202.156,90.666z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M170.478,82.055c0.829,0,1.501-0.672,1.501-1.5v-8.568c0-2.717-2.21-4.928-4.927-4.928h-34.594
				c-2.717,0-4.927,2.211-4.927,4.928v8.568c0,0.828,0.672,1.5,1.501,1.5s1.501-0.672,1.501-1.5v-4.637h38.442v4.637
				C168.976,81.383,169.648,82.055,170.478,82.055z M130.534,71.986c0-1.063,0.863-1.926,1.924-1.926h34.594
				c1.061,0,1.924,0.863,1.924,1.926v2.43h-38.442V71.986z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconSettlement;
