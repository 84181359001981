const messageCenterMessageReplyStyle = {
  paper: {
    padding: "1.5rem",
    height: "100%",
    width: "100%",
    marginTop: "20px",
  },
};

export default messageCenterMessageReplyStyle;
