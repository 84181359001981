import React from "react";
import divorceCostBoxStyle from "./divorceCostBoxStyle";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Divider, Grid } from "@mui/material";
import DialogModal from "../../../DialogModal/DialogModal";
import BankDataContainer from "../BankDataContainer";
import FeesService from "../../../../services/FeesService";
import CostCalculator from "../../../../services/divorce/CostCalculator";
import { modalview, pageview } from "../../../../services/useTracker";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";

type DivorceRealCostsBoxProps = {
  divorce: AbstractCase;
};

const DivorceRealCostsBox = ({ divorce }: DivorceRealCostsBoxProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  if (!divorce) {
    return null;
  }

  const processCosts = divorce.processFeeAndExpenses;
  const valueInDispute = CostCalculator.calculateValueInDispute(divorce);
  const installmentRate = (FeesService.getExpenses(true) + CostCalculator.getHearingCost(valueInDispute) + CostCalculator.getProcessCost(valueInDispute)) / 10;
  const formattedInstallmentRate = (Math.round(installmentRate * 100) / 100).toLocaleString("de-DE");
  const hearingSub = divorce.installment
    ? `Sie zahlen die Kosten für Ihren Anwalt in 10 Raten zu je ${formattedInstallmentRate} €.`
    : "Zu zahlen, sobald Ihr Scheidungstermin fest steht";

  const handleCloseBankLayer = () => {
    navigate(-1);
    pageview(location.pathname);
  };

  const handleOpenBankLayer = () => {
    navigate(location.pathname, { state: { bankModalOpen: "costBox" } });
    modalview("Bankdata Modal");
  };

  const costsSum = processCosts + divorce.hearingFee + divorce.courtFee;

  return (
    <Grid container justifyContent={"flex-end"}>
      <Grid item xs={8} sx={divorceCostBoxStyle.costRealLabel}>
        Verfahrensgebühr
      </Grid>
      <Grid item xs={4} sx={divorceCostBoxStyle.costRealValue}>
        {processCosts.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
      </Grid>
      {divorce.paymentType === "private" ? (
        <Grid item xs={12} sx={divorceCostBoxStyle.costRealSub}>
          <span>Zu zahlen bei Einreichen des Scheidungsantrags</span>
        </Grid>
      ) : (
        [<br key={1} />, <br key={2} />]
      )}
      <Grid item xs={8} sx={divorceCostBoxStyle.costRealLabel}>
        Terminsgebühr
      </Grid>
      <Grid item xs={4} sx={divorceCostBoxStyle.costRealValue}>
        {divorce.hearingFee && divorce.hearingFee.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
      </Grid>
      {divorce.paymentType !== "processCostSupport"
        ? [
            <Grid key={"1"} item xs={12} sx={divorceCostBoxStyle.costRealSub}>
              {hearingSub}
            </Grid>,
            <Grid key={"2"} item xs={12}>
              <Button variant="contained" fullWidth sx={divorceCostBoxStyle.paymentInfoButton} onClick={handleOpenBankLayer}>
                Bankdaten anzeigen
              </Button>
            </Grid>,
          ]
        : [<br key={1} />, <br key={2} />]}
      <Grid item xs={8} sx={divorceCostBoxStyle.costRealLabel}>
        Gerichtskosten
      </Grid>
      <Grid item xs={4} sx={divorceCostBoxStyle.costRealValue}>
        {divorce.courtFee
          ? divorce.courtFee.toLocaleString("de-DE", { style: "currency", currency: "EUR" })
          : divorce.courtFee.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
      </Grid>
      {divorce.paymentType !== "processCostSupport" && (
        <Grid item xs={12} sx={divorceCostBoxStyle.costRealSub}>
          Zu zahlen, sobald Sie die Rechnung vom Gericht erhalten haben
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider sx={divorceCostBoxStyle.divider} />
      </Grid>
      <Grid item xs={8} sx={[divorceCostBoxStyle.costRealLabel, divorceCostBoxStyle.sum]}>
        Scheidungskosten insgesamt
      </Grid>
      <Grid item xs={4} sx={[divorceCostBoxStyle.costRealValue, divorceCostBoxStyle.sum]}>
        {costsSum.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
      </Grid>
      {divorce.hasProcessCostSupport && [
        <Grid key={"3"} item xs={12} sx={divorceCostBoxStyle.paymentHint}>
          Sie müssen voraussichtlich nichts zahlen!
        </Grid>,
        <Grid key={"4"} item xs={12} sx={divorceCostBoxStyle.paymentHintSub}>
          Wenn Ihr Antrag auf Verfahrenskostenhilfe bewilligt worden ist, sind alle Zahlungen über die Verfahrenskostenhilfe abgedeckt.
        </Grid>,
      ]}
      <DialogModal open={!!(location.state && location.state.bankModalOpen === "costBox")} onClose={handleCloseBankLayer} title={"Bankdaten"}>
        <BankDataContainer
          divorce={divorce}
          hearing={true}
          process={true}
          installmentRate={divorce.installment ? formattedInstallmentRate : null}
        />
      </DialogModal>
    </Grid>
  );
};

export default DivorceRealCostsBox;
