import React from "react";
import { Conversation } from "../../../types/Entities/Messaging/Conversation";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import moment from "moment";
import { DraftsOutlined, EmailOutlined } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { conversationHasUnreadMessage } from "../../../services/messagingService";
import { Stack } from "@mui/material";

type ConversationProps = {
  conversation: Conversation;
  setOpenConversationId: (conversationId: number) => void;
};

export default function ConversationRow({ conversation, setOpenConversationId }: ConversationProps) {
  const hasUnreadMessage = conversationHasUnreadMessage(conversation);

  const conversationCreatedDateMoment = moment(conversation.created);

  const conversationCreatedDateIsToday = conversationCreatedDateMoment.isSame(moment(), "day");
  const conversationCreatedDateIsThisYear = conversationCreatedDateMoment.isSame(moment(), "year");

  let dateStringToDisplay;

  switch (true) {
    case conversationCreatedDateIsToday:
      dateStringToDisplay = conversationCreatedDateMoment.format("HH:mm");
      break;
    case conversationCreatedDateIsThisYear:
      dateStringToDisplay = conversationCreatedDateMoment.format("DD.MMM");
      break;
    default:
      dateStringToDisplay = conversationCreatedDateMoment.format("DD.MM.YYYY, HH:mm") + " Uhr";
      break;
  }

  return (
    <TableRow hover sx={{ cursor: "pointer" }} onClick={() => conversation.id && setOpenConversationId(conversation.id)}>
      <TableCell sx={{ display: { xs: "none", sm: "table-cell", width: "auto" } }}>
        <Stack justifyContent={"center"}>{hasUnreadMessage ? <EmailOutlined /> : <DraftsOutlined />}</Stack>
      </TableCell>
      <TableCell sx={{ flexGrow: 1, width: "100%" }}>
        <Typography sx={{ maxHeight: "1.3rem", overflow: "hidden", wordBreak: "break-all" }}>
          {hasUnreadMessage ? <strong>{conversation.subject}</strong> : conversation.subject}
        </Typography>
        {conversation.latestMessageCreatedDate !== conversation.created && (
          <Typography>Neuste Nachricht: {moment(conversation.latestMessageCreatedDate).format("DD.MM.YYYY, HH:mm")} Uhr</Typography>
        )}
      </TableCell>
      <TableCell sx={{ width: "auto" }}>
        <Typography>{dateStringToDisplay}</Typography>
      </TableCell>
    </TableRow>
  );
}
