import React from "react";
import divorcePlannerBoxStyle from "./divorcePlannerBoxStyle";
import { Button, Grid, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import { getCaseLink } from "../../../services/productService";
import ContentBoxHead from "../../Box/ContentBox/ContentBoxHead";
import IconProgress from "../../../assets/icons/serviceOverview/IconProgress";
import { AbstractCase } from "../../../types/Entities/AbstractCase";

type DivorcePlannerBoxProps = {
  product: AbstractCase,
};

const DivorcePlannerBox = ({ product }: DivorcePlannerBoxProps) => {
  if (product.isInitialProxy) {
    return null;
  }

  return (
    <Paper elevation={4} sx={divorcePlannerBoxStyle.paper}>
      <Grid container justifyContent={"center"} spacing={3}>
        <Grid item>
          <ContentBoxHead headline={"Scheidungsplaner"} Icon={IconProgress} />
        </Grid>
        <Grid item>
          Ihr persönlicher Scheidungsplaner passt sich über den gesamten Zeitraum der Scheidung immer an Ihre aktuelle Situation an. So haben Sie die nächsten
          Schritte im Blick und können Ihre Checklisten erledigen.
        </Grid>
        <Grid item>
          <Button color={"primary"} fullWidth={false} component={Link} to={getCaseLink(product) + "/scheidungsplaner"}>
            Zum Scheidungsplaner
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DivorcePlannerBox;
