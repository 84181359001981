import iconAttorneys from "../assets/icons/generic/IconLawyers";
import costsSecureIcon from "../assets/icons/generic/IconCostSecurity";
import ReactGA from "react-ga4";
import iconAttorneyCheck from "../assets/icons/settlement/iconAttorneyCheck";
import iconCalculateSettlement from "../assets/icons/settlement/IconMoney";
import iconInformation from "../assets/icons/generic/IconGuidebook";
import iconDocument from "../assets/icons/generic/IconDocument";
import divorceTeaser from "../assets/img/teaser/familyLaw/Familienrecht_Scheidung_v2.svg";
import divorceTeaserDark from "../assets/img/teaser/familyLaw/Familienrecht_Scheidung_v2_dark.svg";
import alimonyTeaser from "../assets/img/teaser/familyLaw/Familienrecht_Unterhalt_v2.svg";
import alimonyTeaserDark from "../assets/img/teaser/familyLaw/Familienrecht_Unterhalt_v2_dark.svg";
import deathTeaser from "../assets/img/teaser/familyLaw/Familienrecht_Tod_Erbe.svg";
import deathTeaserDark from "../assets/img/teaser/familyLaw/Familienrecht_Tod_Erbe_dark.svg";
import familyLawTeaser from "../assets/img/teaser/familyLaw/Familienrecht.svg";
import familyLawTeaserDark from "../assets/img/teaser/familyLaw/Familienrecht_dark.svg";
import employmentLawTeaser from "../assets/img/products/Illustration_Arbeitsrecht.svg";
import employmentLawTeaserDark from "../assets/img/products/Illustration_Arbeitsrecht_dark.svg";
import settlementTeaser from "../assets/img/teaser/employmentLaw/Arbeitsrecht_Kuendigungsschutz.svg";
import settlementTeaserDark from "../assets/img/teaser/employmentLaw/Arbeitsrecht_Kuendigungsschutz_dark.svg";
import trafficTeaser from "../assets/img/products/Illustration_Verkehrsrecht.svg";
import trafficTeaserDark from "../assets/img/products/Illustration_Verkehrsrecht_dark.svg";
import redLightTeaser from "../assets/img/teaser/trafficLaw/Verkehrsrecht_Rotlichtverstoss.svg";
import redLightTeaserDark from "../assets/img/teaser/trafficLaw/Verkehrsrecht_Rotlichtverstoss_dark.svg";
import distanceTeaser from "../assets/img/teaser/trafficLaw/Verkehrsrecht_Abstandsverstoss.svg";
import distanceTeaserDark from "../assets/img/teaser/trafficLaw/Verkehrsrecht_Abstandsverstoss_dark.svg";
import speedingTeaser from "../assets/img/teaser/trafficLaw/Verkehrsrecht_Geschwindigkeitsverstoss_v2.svg";
import speedingTeaserDark from "../assets/img/teaser/trafficLaw/Verkehrsrecht_Geschwindigkeitsverstoss_v2_dark.svg";
import trafficOtherTeaser from "../assets/img/teaser/trafficLaw/Verkehrsrecht_Sonstiges.svg";
import trafficOtherTeaserDark from "../assets/img/teaser/trafficLaw/Verkehrsrecht_Sonstiges_dark.svg";
import contractLawTeaser from "../assets/img/products/Illustration_Vertragsrecht.svg";
import contractLawTeaserDark from "../assets/img/products/Illustration_Vertragsrecht_dark.svg";
import tenancyLawTeaser from "../assets/img/products/Illustration_Mietrecht.svg";
import tenancyLawTeaserDark from "../assets/img/products/Illustration_Mietrecht_dark.svg";
import purchaseContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Kaufvertrag.svg";
import purchaseContractTeaserDark from "../assets/img/teaser/contractLaw/Vertragsrecht_Kaufvertrag_dark.svg";
import vehiclePurchaseContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Fahrzeugkauf.svg";
import vehiclePurchaseContractTeaserDark from "../assets/img/teaser/contractLaw/Vertragsrecht_Fahrzeugkauf_dark.svg";
import serviceContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Handwerker.svg";
import serviceContractTeaserDark from "../assets/img/teaser/contractLaw/Vertragsrecht_Handwerker_dark.svg";
import membershipContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Mitgliedschaft.svg";
import membershipContractTeaserDark from "../assets/img/teaser/contractLaw/Vertragsrecht_Mitgliedschaft_dark.svg";
import subscriptionContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Abofalle.svg";
import subscriptionContractTeaserDark from "../assets/img/teaser/contractLaw/Vertragsrecht_Abofalle_dark.svg";
import providerContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Handy_etc.svg";
import providerContractTeaserDark from "../assets/img/teaser/contractLaw/Vertragsrecht_Handy_etc_dark.svg";
import travelContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Reise.svg";
import travelContractTeaserDark from "../assets/img/teaser/contractLaw/Vertragsrecht_Reise_dark.svg";
import eventContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Veranstaltung.svg";
import eventContractTeaserDark from "../assets/img/teaser/contractLaw/Vertragsrecht_Veranstaltung_dark.svg";
import otherContractTeaser from "../assets/img/teaser/contractLaw/Vertragsrecht_Sonstiges.svg";
import otherContractTeaserDark from "../assets/img/teaser/contractLaw/Vertragsrecht_Sonstiges_dark.svg";
import rentIncreaseImage from "../assets/img/teaser/tenancyLaw/Mietvertrag_mieterhoehung.svg";
import rentIncreaseImageDark from "../assets/img/teaser/tenancyLaw/Mietvertrag_mieterhoehung_dark.svg";
import cancellationImage from "../assets/img/teaser/tenancyLaw/Mietrecht_Kuendigung.svg";
import cancellationImageDark from "../assets/img/teaser/tenancyLaw/Mietrecht_Kuendigung_dark.svg";
import AnwaelteWbs from "../assets/icons/landingpage/wbs/IconLawyersWbs";
import IconCostSecurityWbs from "../assets/icons/landingpage/wbs/IconCostSecurityWbs";
import ThemedIllustration from "../components/ThemedIconSwitch/ThemedIllustration";

export const legalFieldPageConfig = {
  familyLaw: {
    headline: "Familienrecht",
    subtitle: "Ob Scheidung, Unterhalt oder alle anderen Themen im Familienrecht - unsere Anwälte sind jederzeit für Sie da!",
    headlineImage: <ThemedIllustration lightIllustration={familyLawTeaser} darkIllustration={familyLawTeaserDark} />,
    zipperTeaserRows: [
      {
        headline: "Scheidung",
        subText: "Wir begleiten Sie professionell und persönlich bei Ihrer Scheidung.",
        image: <ThemedIllustration lightIllustration={divorceTeaser} darkIllustration={divorceTeaserDark} />,
      },
      {
        headline: "Unterhalt",
        subText: "Ehegatten-, Kindesunterhalt oder andere Fragen rund um das Thema Unterhalt.",
        image: <ThemedIllustration lightIllustration={alimonyTeaser} darkIllustration={alimonyTeaserDark} />,
      },
      {
        headline: "Todesfall & Erbrecht",
        subText: "Beratung bei allen Angelegenheiten rund um das Thema Erbrecht.",
        image: <ThemedIllustration lightIllustration={deathTeaser} darkIllustration={deathTeaserDark} />,
      },
    ],
  },
  trafficLaw: {
    headline: "Verkehrsrecht",
    subtitle: "Zu schnell gefahren oder rote Ampel übersehen? Wir tun alles, damit Sie Ihren Führerschein behalten können!",
    headlineImage: <ThemedIllustration lightIllustration={trafficTeaser} darkIllustration={trafficTeaserDark} />,
    zipperTeaserRows: [
      {
        headline: "Tempoverstoß",
        subText: "Bußgeld und Punkte wegen Geschwindigkeitsverstoß vermeiden oder reduzieren.",
        image: <ThemedIllustration lightIllustration={speedingTeaser} darkIllustration={speedingTeaserDark} />,
      },
      {
        headline: "Rotlichtverstoß",
        subText: "Professionell gegen Bußgeld oder Fahrverbot wegen überfahrenen Rotlichts vorgehen.",
        image: <ThemedIllustration lightIllustration={redLightTeaser} darkIllustration={redLightTeaserDark} />,
      },
      {
        headline: "Abstandsverstoß",
        subText: "Zu nah aufgefahren? Wir prüfen das vermeintliche Vergehen und setzen uns für Sie ein.",
        image: <ThemedIllustration lightIllustration={distanceTeaser} darkIllustration={distanceTeaserDark} />,
      },
      {
        headline: "Handy/ Drogen/ Parken/ Sonstiges",
        subText: "Hilfestellung bei allen sonstigen Arten von Bußgeldern im Bereich Verkehr.",
        image: <ThemedIllustration lightIllustration={trafficOtherTeaser} darkIllustration={trafficOtherTeaserDark} />,
      },
    ],
  },
  employmentLaw: {
    headline: "Arbeitsrecht",
    subtitle: "Ärger mit dem Arbeitgeber? Wir helfen bei allen Problemen rund um Ihrem Arbeitsplatz.",
    headlineImage: <ThemedIllustration lightIllustration={employmentLawTeaser} darkIllustration={employmentLawTeaserDark} />,
    zipperTeaserRows: [
      {
        headline: "Abfindung bei Kündigung",
        subText: "Wir gehen gegen Ihre Kündigung vor und Erwirken - wenn gewünscht - eine Abfindung für Sie.",
        image: <ThemedIllustration lightIllustration={settlementTeaser} darkIllustration={settlementTeaserDark} />,
      },
    ],
  },
  contractLaw: {
    headline: "Vertragsrecht",
    subtitle: "Ob Ärger mit dem Fitnessstudio, dem Stromanbieter oder mit anderen Verträgen - wir setzen uns für Sie ein.",
    headlineImage: <ThemedIllustration lightIllustration={contractLawTeaser} darkIllustration={contractLawTeaserDark} />,
    zipperTeaserRows: [
      {
        headline: "Kaufvertrag",
        subText: "Unterstützung bei Problemen mit Ihrem Kaufvertrag - egal ob als Käufer oder Verkäufer.",
        image: <ThemedIllustration lightIllustration={purchaseContractTeaser} darkIllustration={purchaseContractTeaserDark} />,
      },
      {
        headline: "Fahrzeugkauf",
        subText: "Hilfe bei allen Vertragsthemen rund um den Kauf oder Verkauf von Fahrzeugen.",
        image: <ThemedIllustration lightIllustration={vehiclePurchaseContractTeaser} darkIllustration={vehiclePurchaseContractTeaserDark} />,
      },
      {
        headline: "Handwerker / Dienstleistung",
        subText: "Wir setzen uns für Sie ein, wenn zum Beispiel Ihr Handwerker die Leistung nicht wie vereinbart erfüllt.",
        image: <ThemedIllustration lightIllustration={serviceContractTeaser} darkIllustration={serviceContractTeaserDark} />,
      },
      {
        headline: "Mitgliedschaft",
        subText: "Uneinigkeiten mit Ihren Mitgliedschaften, beispielsweise Ihrem Fitnessstudio oder Sportverein.",
        image: <ThemedIllustration lightIllustration={membershipContractTeaser} darkIllustration={membershipContractTeaserDark} />,
      },
      {
        headline: "Abo / Abofalle",
        subText: "Sie sind in eine Abofalle geraten? Wir wehren die Forderung für Sie ab.",
        image: <ThemedIllustration lightIllustration={subscriptionContractTeaser} darkIllustration={subscriptionContractTeaserDark} />,
      },
      {
        headline: "Handy / DSL / Strom / Gas-Vertrag",
        subText: "Für alle Probleme mit Versorgern, wie Mobilfunkanbietern oder Stromversorger.",
        image: <ThemedIllustration lightIllustration={providerContractTeaser} darkIllustration={providerContractTeaserDark} />,
      },
      {
        headline: "Reisevertrag",
        subText: "Reisen sollten entspannend sein, daher helfen wir bei allen Problemen rund um dem Reisevertrag.",
        image: <ThemedIllustration lightIllustration={travelContractTeaser} darkIllustration={travelContractTeaserDark} />,
      },
      {
        headline: "Veranstaltung",
        subText: "Veranstaltung ausgefallen, verschoben oder sonstige Probleme? Wir unterstützen Sie!",
        image: <ThemedIllustration lightIllustration={eventContractTeaser} darkIllustration={eventContractTeaserDark} />,
      },
      {
        headline: "Sonstiger Vertrag",
        subText: "Auch bei allen anderen Verträgen helfen wir Ihnen weiter! Eine kurze Schilderung genügt!",
        image: <ThemedIllustration lightIllustration={otherContractTeaser} darkIllustration={otherContractTeaserDark} />,
      },
    ],
  },
  tenancyLaw: {
    headline: "Mietrecht",
    subtitle: "Bei allen Anliegen rund um Ihren Mietvertrag, setzen wir uns professionell für Ihre Rechte ein!",
    headlineImage: <ThemedIllustration lightIllustration={tenancyLawTeaser} darkIllustration={tenancyLawTeaserDark} />,
    zipperTeaserRows: [
      {
        headline: "Mietmängel",
        subText: "Heizung ausgefallen oder Schimmel im Schlafzimmer? Wir sorgen dafür, dass Mietmängel zeitnah behoben werden.",
        image: <ThemedIllustration lightIllustration={purchaseContractTeaser} darkIllustration={purchaseContractTeaserDark} />,
      },
      {
        headline: "Mietminderung",
        subText: "Lärmbelästigung oder andere Mängel, die nicht behoben werden, dann helfen wir Ihnen dabei, die Miete zu mindern.",
        image: <ThemedIllustration lightIllustration={subscriptionContractTeaser} darkIllustration={subscriptionContractTeaserDark} />,
      },
      {
        headline: "Mieterhöhung",
        subText: "Ihre Miete wurde unrechtmäßig erhöht und Sie möchten dagegen vorgehen.",
        image: <ThemedIllustration lightIllustration={rentIncreaseImage} darkIllustration={rentIncreaseImageDark} />,
      },
      {
        headline: "Nebenkostenabrechnung",
        subText: "Sie haben eine sehr hohe Abrechnung erhalten, die Ihnen falsch erscheint? Wir prüfen Ihre Nebenkostenabrechnung auf Rechtmäßigkeit.",
        image: <ThemedIllustration lightIllustration={otherContractTeaser} darkIllustration={otherContractTeaserDark} />,
      },
      {
        headline: "Kaution",
        subText: "Wenn die Kaution Ihnen gar nicht oder nicht vollständig zurückgezahlt wird, setzen wir uns für Sie ein.",
        image: <ThemedIllustration lightIllustration={divorceTeaser} darkIllustration={divorceTeaserDark} />,
      },
      {
        headline: "Kündigung",
        subText: "Ihnen wurde das Mietverhältnis gekündigt und Sie möchten wissen, ob dies rechtmäßig war? Dann prüfen wir dies gerne für Sie.",
        image: <ThemedIllustration lightIllustration={cancellationImage} darkIllustration={cancellationImageDark} />,
      },
      {
        headline: "Mietvertrag prüfen",
        subText: "Sie möchten einen neuen Mietvertrag unterzeichnen oder sind allgemein unsicher, ob Ihr Vertrag rechtmäßig ist.",
        image: <ThemedIllustration lightIllustration={otherContractTeaser} darkIllustration={otherContractTeaserDark} />,
      },
    ],
  },
};

export const defaultProvenExpertTeaserCard = {
  icon: "https://images.provenexpert.com/ca/70/17a7e460654ab3270025208dddab/widget_square_200_de_0.png",
  heading: "Persönlich & schnell",
  link: "https://www.provenexpert.com/legalbird/?utm_source=Widget&amp;utm_medium=Widget&amp;utm_campaign=Widget",
  linkType: "external",
  hideMobileButton: true,
};

export const getProvenExpertTeaserCardWithHeading = (heading) => {
  let provenExpertTeaserCard = { ...defaultProvenExpertTeaserCard };
  provenExpertTeaserCard.heading = heading;
  return provenExpertTeaserCard;
};

const handleClickGA = (actionName) => {
  ReactGA.event({
    category: "dismissal action choice",
    action: actionName,
  });
};

export const divorceTeaserCards = [
  defaultProvenExpertTeaserCard,
  {
    icon: iconAttorneys,
    heading: "Professionelle Beratung",
  },
  {
    icon: costsSecureIcon,
    heading: "Kostenlose Prüfung",
  },
];

export const wbsTeaserCards = [
  {
    icon: IconCostSecurityWbs,
    heading: "Kostenlose Prüfung",
  },
  {
    icon: AnwaelteWbs,
    heading: "Professionelle Beratung",
  },
  defaultProvenExpertTeaserCard,
];

export const settlementChoiceCards = [
  {
    icon: iconAttorneyCheck,
    heading: "Kostenlos prüfen",
    link: "/lp/abfindung",
    onClick: () => handleClickGA("dismissal check"),
  },
  {
    icon: iconCalculateSettlement,
    heading: "Abfindung berechnen",
    link: "/lp/abfindung",
    onClick: () => handleClickGA("calculate settlement"),
  },
  {
    icon: iconInformation,
    heading: "Allgemein informieren",
    link: "/ratgeber/arbeitsrecht",
    onClick: () => handleClickGA("more information"),
  },
];

export const settlementTeaserCards = [
  defaultProvenExpertTeaserCard,
  {
    icon: iconAttorneys,
    heading: "Professionelle Beratung",
  },
  {
    icon: costsSecureIcon,
    heading: "Kostenlose Prüfung",
  },
];

export const contractTeaserCards = [
  defaultProvenExpertTeaserCard,
  {
    icon: iconAttorneys,
    heading: "Professionelle Beratung",
  },
  {
    icon: iconDocument,
    heading: "Unkomplizierte Abwicklung",
  },
];

export const alimonyTeaserCards = [
  getProvenExpertTeaserCardWithHeading("Schnell & effektiv"),
  {
    icon: iconAttorneys,
    heading: "Professionelle Beratung",
  },
  {
    icon: costsSecureIcon,
    heading: "Unterhalt berechnen",
  },
];

export const documentTeaserCards = [
  getProvenExpertTeaserCardWithHeading("Schnell & effektiv"),
  {
    icon: iconAttorneys,
    heading: "Professionelle Beratung",
  },
  {
    icon: iconDocument,
    heading: "Persönliches Dokument",
  },
];

export const trafficTeaserCards = [
  getProvenExpertTeaserCardWithHeading("Schnell & effektiv"),
  {
    icon: iconAttorneys,
    heading: "Professionelle Beratung",
  },
  {
    icon: costsSecureIcon,
    heading: "Kostenlose Ersteinschätzung",
  },
];
