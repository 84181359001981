/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import settlementTimeSectionStyle from "./settlementTimeSectionStyle";
import ContainerFullWidth from "../../../Container/ContainerFullWidth";
import TextContainer from "../../../Container/TextContainer";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

type SettlementTimeSectionProps = {
  sectionPadding?: boolean;
};

const SettlementTimeSection = ({ sectionPadding = false }: SettlementTimeSectionProps) => {
  return (
    <ContainerFullWidth sx={settlementTimeSectionStyle.timeSection} sectionPadding={sectionPadding}>
      <Box sx={settlementTimeSectionStyle.scrollToAnchor} id="drei-wochen-frist" />
      <Typography variant={"h2"} sx={settlementTimeSectionStyle.headline}>
        Handeln Sie schnell!
      </Typography>
      <TextContainer>
        <Typography variant={"subtitle1"}>3-Wochen-Frist bei Kündigung</Typography>
        <p>
          Nach Erhalt einer Kündigung können Sie nur 3 Wochen lang gegen diese vorgehen - danach ist die Kündigung automatisch rechtswirksam! Lassen Sie sich
          die Chance auf eine Abfindung oder die Sicherung Ihres Arbeitsplatzes nicht nehmen!
        </p>
      </TextContainer>
    </ContainerFullWidth>
  );
};

export default SettlementTimeSection;
