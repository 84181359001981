/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import TextContainer from "../../../Container/TextContainer";
import CostsSecureIcon from "../../../../assets/icons/generic/IconCostSecurity";
import ContainerFullWidth from "../../../Container/ContainerFullWidth";
import Typography from "@mui/material/Typography";
import noWinNoFeeSectionStyle from "./noWinNoFeeSectionStyle";
import { Box } from "@mui/material";

type NoWinNoFeeSectionProps = {
  sectionPadding?: boolean;
};

const NoWinNoFeeSection = ({ sectionPadding = false }: NoWinNoFeeSectionProps) => {
  return (
    <ContainerFullWidth sx={noWinNoFeeSectionStyle.noWinNoFeeSection} sectionPadding={sectionPadding}>
      <Box sx={noWinNoFeeSectionStyle.scrollToAnchor} id="kein-kostenrisiko" />
      <Typography variant={"h2"}>Fordern Sie Ihr Recht ein - komplett ohne Kostenrisiko!</Typography>
      <CostsSecureIcon sx={noWinNoFeeSectionStyle.costsSecureIcon} />
      <TextContainer>
        <Typography variant={"h3"} sx={noWinNoFeeSectionStyle.h3}>
          Unser Versprechen an Sie: Kostenlose Prüfung
        </Typography>
        <p>Wir prüfen Ihre Kündigung kostenlos und sorgen dafür, dass Sie sich auch danach über die Kosten keine Sorgen machen müssen.</p>
        <Typography variant={"h3"} sx={noWinNoFeeSectionStyle.h3}>
          Auch nach der Prüfung: Ohne Risiko gegen Kündigung vorgehen
        </Typography>
        <Typography variant={"h4"} sx={noWinNoFeeSectionStyle.subHeadline}>
          Ohne Rechtsschutzversicherung
        </Typography>
        <p>
          Sie möchten gegen die Kündigung vorgehen, machen sich aber Sorgen wegen der Kosten? Nicht bei uns! Unser Prozesskostenfinanzierer übernimmt vorab alle
          Anwalts- und Gerichtskosten für Sie! Sie bezahlen dann am Ende nur bei Erfolg eine Erfolgsprovision.
        </p>
        <Typography variant={"h4"} sx={noWinNoFeeSectionStyle.subHeadline}>
          Mit Rechtsschutzversicherung
        </Typography>
        <p>
          Sie haben eine Rechtsschutzversicherung für Arbeitsrecht? Dann fallen für Sie keine Kosten an! Legalbird übernimmt sogar den Selbstbehalt für Sie!
        </p>
      </TextContainer>
    </ContainerFullWidth>
  );
};

export default NoWinNoFeeSection;
