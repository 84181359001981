import React, { useEffect, useRef, useState } from "react";
import { MinimalCase } from "../../../types/Entities/MinimalCase";
import { Conversation } from "../../../types/Entities/Messaging/Conversation";
import _orderBy from "lodash/orderBy";
import Grid from "@mui/material/Grid";
import { Box, Pagination, Stack, TableHead, TableSortLabel } from "@mui/material";
import ServiceWorldContentBox from "../../Serviceworld/ServiceWorldContentBox";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import ConversationRow from "./ConversationRow";
import Button from "@mui/material/Button";
import { Link, useLocation } from "react-router-dom";
import MessageCenterConversation from "./MessageCenterConversation";
import { getNewMessageLink } from "../../../services/messagingService";
import EmailVerificationModal from "../../FormInfoModal/EmailVerificationModal";
import MessageCenterNoCasesMessage from "./MessageCenterNoCasesMessage";
import { scrollToAnchor } from "../../../theme/commonStyle";
import MessagesCasePicker from "./MessagesCasePicker";
import MessageCenterMessageReply from "./MessageCenterMessageReply";

type MessageCenterProps = {
  minimalCases: Array<MinimalCase>;
  conversations: Array<Conversation>;
};

const ITEMS_PER_PAGE = 20;

export default function MessageCenter({ minimalCases, conversations }: MessageCenterProps) {
  const [selectedCase, setSelectedCase] = useState<MinimalCase | null>(null);
  const [page, setPage] = useState(0);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [openConversationId, setOpenConversationId] = useState<number | null>(null);
  const messagesBoxRef = useRef<null | HTMLDivElement>();
  const openConversation: Conversation | undefined = conversations.find((conversation) => conversation.id === openConversationId);
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);

  useEffect(() => {
    if (minimalCases && minimalCases.length > 0) {
      const initialCaseId = urlSearchParams.get("caseId");
      const initialSelectedCase = minimalCases.find((minimalCase) => minimalCase.id + "" === initialCaseId);

      if (initialSelectedCase) {
        setSelectedCase(initialSelectedCase);
        return;
      }
      const orderedMinimalCases = _orderBy(minimalCases, ["created"], ["desc"]);
      setSelectedCase(orderedMinimalCases[0]);
    }
  }, [minimalCases]);

  const sortComparisonFunction = (conversationA: Conversation, conversationB: Conversation) => {
    if (conversationA.latestMessageCreatedDate < conversationB.latestMessageCreatedDate) {
      return sortDirection === "desc" ? 1 : -1;
    }
    if (conversationA.latestMessageCreatedDate > conversationB.latestMessageCreatedDate) {
      return sortDirection === "asc" ? 1 : -1;
    }
    return 0;
  };

  const filteredConversations = conversations.filter((conversation) => selectedCase && conversation.channel.caseId === selectedCase.id);
  let sortedFilteredConversations = filteredConversations;
  sortedFilteredConversations.sort(sortComparisonFunction);

  const handlePaginationChange = (e: React.ChangeEvent<unknown>, page: number) => {
    setPage(page - 1);
    scrollToMessagesBox();
  };

  const scrollToMessagesBox = () => {
    messagesBoxRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const currentIndexDerivedFromPage = page * ITEMS_PER_PAGE;
  const paginatedFilteredConversations = sortedFilteredConversations.slice(currentIndexDerivedFromPage, currentIndexDerivedFromPage + ITEMS_PER_PAGE);
  const handleConversationClick = (minimalCase: MinimalCase) => {
    setSelectedCase(minimalCase);
    setOpenConversationId(null);
    setPage(0);
    scrollToMessagesBox();
  };

  return (
    <>
      <Grid container spacing={2}>
        {minimalCases.length > 0 && (
          <Grid item xs={12} md={4}>
            <MessagesCasePicker handleConversationClick={handleConversationClick} selectedCase={selectedCase} />
          </Grid>
        )}
        <Grid item xs={12} md={minimalCases.length === 0 ? 12 : 8}>
          <Box ref={messagesBoxRef} sx={scrollToAnchor} />
          {openConversation && selectedCase && (
            <>
              <MessageCenterConversation
                conversation={openConversation}
                setOpenConversationId={setOpenConversationId}
                key={openConversation.id}
                product={selectedCase}
              />
              <MessageCenterMessageReply product={selectedCase} conversation={openConversation} />
            </>
          )}
          {!openConversation && (
            <ServiceWorldContentBox headline={"Nachrichten"}>
              {minimalCases.length === 0 ? (
                <MessageCenterNoCasesMessage />
              ) : (
                <Box sx={!selectedCase ? { pointerEvents: "none" } : {}}>
                  {paginatedFilteredConversations.length === 0 ? (
                    <Typography textAlign={"center"} sx={{ margin: "2rem", opacity: 0.4 }}>
                      Keine Nachrichten für diesen Fall vorhanden
                    </Typography>
                  ) : (
                    <>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell scope={"col"} sx={{ display: { xs: "none", sm: "table-cell" } }} />
                            <TableCell scope={"col"} />
                            <TableCell scope={"col"} width={"80px"}>
                              <TableSortLabel
                                active
                                direction={sortDirection}
                                onClick={() => setSortDirection((prevState) => (prevState === "asc" ? "desc" : "asc"))}
                              >
                                Datum
                              </TableSortLabel>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {paginatedFilteredConversations.map((conversation) => (
                            <ConversationRow setOpenConversationId={setOpenConversationId} key={conversation.id} conversation={conversation} />
                          ))}
                        </TableBody>
                      </Table>
                      <Stack alignItems={"center"} sx={{ margin: "2rem" }}>
                        <Pagination count={Math.ceil(filteredConversations.length / ITEMS_PER_PAGE)} onChange={handlePaginationChange} />
                      </Stack>
                    </>
                  )}
                </Box>
              )}
              <Grid container direction={"row"} justifyContent={"space-evenly"} flexDirection={"row-reverse"} sx={{ padding: "1rem" }} spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    disabled={!selectedCase}
                    component={Link}
                    to={getNewMessageLink(selectedCase?.name || "", selectedCase?.id || 0)}
                    fullWidth
                    variant={"contained"}
                  >
                    Neue Nachricht senden
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button component={Link} to={"/servicewelt"} fullWidth>
                    Zurück zu “Meine Fälle”
                  </Button>
                </Grid>
              </Grid>
            </ServiceWorldContentBox>
          )}
        </Grid>
      </Grid>
      <EmailVerificationModal />
    </>
  );
}
