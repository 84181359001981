import React, { ReactElement } from "react";
import teaserStyle from "./teaserStyle";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Container from "../Container/Container";
import IconWrapper, { IconWrapperProps } from "../IconWrapper/IconWrapper";
import { ButtonProps } from "@mui/material/Button";

export type TeaserProps = {
  imageLeftHandSide?: boolean;
  image: IconWrapperProps["icon"];
  imageAlt?: string;
  headline: string;
  subText: string;
  button?: ReactElement<ButtonProps>;
  variant?: string;
};

export default function Teaser({ imageLeftHandSide = false, image, imageAlt, headline, subText, button, variant = "standard" }: TeaserProps) {
  if (variant === "compact") {
    return (
      <Container>
        <Grid container flexDirection={imageLeftHandSide ? "row" : "row-reverse"} alignItems={"center"} spacing={1}>
          <Grid item xs={12} md={3}>
            <IconWrapper icon={image} alt={imageAlt} sx={teaserStyle.smallImage} />
          </Grid>
          <Grid item xs={12} md={9} sx={teaserStyle.textContent}>
            <Typography variant={"h3"} component={"h2"}>
              {headline}
            </Typography>
            <Typography variant={"subtitle2"} sx={teaserStyle.subText}>
              {subText}
            </Typography>
            {button}
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container>
      <Grid container flexDirection={imageLeftHandSide ? "row" : "row-reverse"} alignItems={"center"} spacing={1}>
        <Grid item xs={12} md={5}>
          <IconWrapper icon={image} alt={imageAlt} />
        </Grid>
        <Grid item xs={12} md={7} sx={teaserStyle.textContent}>
          <Typography variant={"h2"}>{headline}</Typography>
          <Typography sx={teaserStyle.subText}>{subText}</Typography>
          {button}
        </Grid>
      </Grid>
    </Container>
  );
}
