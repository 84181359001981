/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { Grid, InputAdornment } from "@mui/material";
import FormLegend from "../../Common/FormLegend/FormLegend";
import { translate, translateHelperTextIfExists } from "../../../../services/translations/translations";
import { floatFieldValidator, numberFieldDefault, requiredValidator } from "../../../../services/validationRules";
import React, { useEffect } from "react";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import { getFormDefinitions } from "../../../../services/formDefinitions/formDefinitions";
import { ValidatorType } from "../../../../types/ValidatorType";
import { ThousandsNumberInput } from "../../../FormFields/MaskedInputs/ThousandsNumberInput";
import { ThousandsNumberInputFloat } from "../../../FormFields/MaskedInputs/ThousandsNumberInputFloat";
import wizardStyle from "../../wizardStyle";

type NumberFieldConfiguredProps = {
  caseData: Record<string, any>;
  fieldPath: string;
  required?: boolean | undefined;
  numberType?: "float" | "int";
  fieldType?: "money" | null;
  inputProps?: any;
  gridItemXs?: number;
};
export default function NumberFieldConfigured({
  caseData,
  fieldPath,
  required,
  numberType = "int",
  fieldType = null,
  inputProps = null,
  gridItemXs = 12,
}: NumberFieldConfiguredProps) {
  const helperText = translateHelperTextIfExists(caseData, fieldPath);
  const question = translate(`${caseData.name}.${fieldPath}.question`, caseData, ""); // "" is the default value here because the netIncomePetitioner and netIncomePartner fields in the divorce form aren't supposed to have headings
  const label = translate(`${caseData.name}.${fieldPath}.label`, caseData);
  const validators: Array<ValidatorType> = [];

  useEffect(() => {
    if (required) {
      validators.push(requiredValidator());
    }
    if (numberType === "int") {
      validators.push(numberFieldDefault);
    }
    if (numberType === "float") {
      validators.push(floatFieldValidator);
    }
    if (getFormDefinitions(caseData, fieldPath) && getFormDefinitions(caseData, fieldPath).validators) {
      const definedValidators: Array<ValidatorType> = getFormDefinitions(caseData, fieldPath).validators;
      definedValidators.forEach((definedValidator) => {
        validators.push(definedValidator);
      });
    }
  }, []);

  const endAdornments = {
    money: "€",
  };

  return (
    <>
      {question && (
        <Grid item xs={gridItemXs} sx={wizardStyle.gridTopSpaced}>
          <FormLegend>{question}</FormLegend>
        </Grid>
      )}

      <Grid item xs={gridItemXs}>
        <ValidatorTextField
          name={fieldPath}
          label={label}
          helperText={helperText}
          validators={validators}
          InputProps={{
            inputComponent: numberType === "float" ? ThousandsNumberInputFloat : ThousandsNumberInput,
            startAdornment: !!fieldType ? <InputAdornment position="start">{endAdornments[fieldType]}</InputAdornment> : null,
            ...inputProps,
          }}
        />
      </Grid>
    </>
  );
}
