import { Grid, Typography } from "@mui/material";
import { Editor } from "react-draft-wysiwyg";
import React from "react";
import { EditorState } from "draft-js";

type MessageFormEditorProps = {
  editorState: EditorState;
  setEditorState: Function;
  currentEditorStatePlainTextLength: number;
  editorStateLengthError: string | null;
};

export default function MessageFormEditor({ editorState, setEditorState, currentEditorStatePlainTextLength, editorStateLengthError }: MessageFormEditorProps) {
  const handleEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant={"subtitle2"} sx={{ textAlign: "center" }}>
          Bitte geben Sie hier Ihre Nachricht ein. Sie haben im nächsten Schritt noch die Möglichkeit Ihrer Nachricht Dateien anzuhängen.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Editor
          toolbar={{
            options: ["inline", "list"],
            inline: {
              options: ["bold", "underline"],
            },
            list: {
              options: ["unordered", "ordered"],
            },
          }}
          stripPastedStyles
          editorState={editorState}
          editorStyle={{ minHeight: 250 }}
          wrapperStyle={{ border: "1px solid #D3D3D3", borderRadius: "4px", padding: "1rem" }}
          onEditorStateChange={handleEditorStateChange}
          localization={{ locale: "de" }}
        />
        <Typography sx={currentEditorStatePlainTextLength > 5000 ? { color: "red" } : {}}>[{currentEditorStatePlainTextLength} / 5000]</Typography>
        {editorStateLengthError && (
          <Typography variant={"body2"} align={"center"} color={"red"}>
            {editorStateLengthError}
          </Typography>
        )}
      </Grid>
    </>
  );
}
