import { Theme } from "@mui/material";

const teaserStyle = {
  subText: {
    margin: "1rem 0",
  },
  textContent: (theme: Theme) => ({
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  }),
  button: {
    minWidth: "200px",
  },
  smallImage: {
    maxWidth: "100%",
    width: "200px",
    height: "auto",
    display: "block",
    margin: "0 auto",
  },
};

export default teaserStyle;
