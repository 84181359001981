import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconDocumentCreate = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59">
        <g>
          <path
            fill="none"
            d="M148.279,27.574h-28.622c-4.697,0-9.451,2.903-9.451,8.451v2.115c0,5.548,4.754,8.451,9.451,8.451h28.622
			c4.695,0,9.449-2.903,9.449-8.451v-2.115C157.728,30.477,152.975,27.574,148.279,27.574z"
          />
          <path
            fill="none"
            d="M174.655,173.882c0.801,0.485,1.59,0.989,2.365,1.517c0.067,0.045,0.135,0.088,0.202,0.134
			c0.799,0.55,1.58,1.127,2.35,1.721c0.149,0.115,0.298,0.229,0.446,0.345c0.747,0.588,1.479,1.197,2.197,1.827
			c0.131,0.115,0.26,0.233,0.39,0.35c0.743,0.666,1.475,1.346,2.184,2.054c0.71,0.709,1.391,1.442,2.058,2.186
			c0.115,0.128,0.232,0.255,0.346,0.385c0.632,0.719,1.242,1.454,1.832,2.202c0.114,0.145,0.225,0.29,0.337,0.436
			c0.596,0.772,1.176,1.556,1.727,2.358c0.042,0.062,0.082,0.125,0.124,0.187c0.532,0.781,1.04,1.578,1.529,2.386
			c0.049,0.081,0.104,0.157,0.153,0.238l25.717-25.717c0.401-1.159,1.31-2.068,2.469-2.469l15.805-15.805
			c-0.026-0.046-0.052-0.093-0.078-0.139c-0.48-0.831-0.987-1.646-1.516-2.448c-0.089-0.135-0.177-0.269-0.268-0.403
			c-0.534-0.794-1.091-1.573-1.671-2.336c-0.093-0.122-0.187-0.241-0.281-0.362c-0.599-0.772-1.218-1.532-1.864-2.271
			c-0.072-0.083-0.147-0.163-0.22-0.245c-0.684-0.773-1.388-1.531-2.122-2.265c-0.731-0.731-1.487-1.433-2.257-2.116
			c-0.083-0.074-0.165-0.15-0.249-0.223c-0.736-0.644-1.493-1.261-2.264-1.858c-0.123-0.096-0.246-0.192-0.369-0.286
			c-0.761-0.579-1.539-1.134-2.331-1.667c-0.136-0.092-0.272-0.182-0.409-0.272c-0.802-0.528-1.617-1.036-2.448-1.517
			c-0.046-0.026-0.093-0.051-0.139-0.078l-43.994,43.993C174.49,173.773,174.57,173.831,174.655,173.882z M191.623,182.019
			l37.256-37.255c0.691-0.691,1.812-0.691,2.503,0c0.691,0.691,0.691,1.812,0,2.503l-37.256,37.255
			c-0.346,0.345-0.799,0.518-1.251,0.518c-0.453,0-0.906-0.172-1.251-0.518C190.932,183.831,190.932,182.71,191.623,182.019z"
          />
          <g>
            <polygon
              fill="#137F7B"
              points="140.716,78.091 127.494,78.091 127.494,99.953 105.632,99.953 105.632,113.177 127.494,113.177
				127.494,135.037 140.716,135.037 140.716,113.177 162.579,113.177 162.579,99.953 140.716,99.953 			"
            />
            <path
              fill="#137F7B"
              d="M251.206,127.713l-12.307-12.304c-0.767-0.768-1.817-1.19-2.958-1.19c-1.291,0-2.579,0.548-3.535,1.502
				l-6.016,6.016l-5.4,5.4c0.083,0.05,0.163,0.107,0.246,0.158c0.803,0.487,1.594,0.992,2.37,1.521
				c0.067,0.046,0.137,0.089,0.204,0.135c0.381,0.262,0.755,0.534,1.13,0.807c0.412,0.299,0.819,0.605,1.223,0.917
				c0.147,0.113,0.294,0.226,0.44,0.341c0.748,0.59,1.482,1.199,2.2,1.83c0.128,0.113,0.254,0.229,0.381,0.342
				c0.743,0.666,1.475,1.347,2.183,2.055c0.708,0.708,1.388,1.439,2.053,2.182c0.117,0.13,0.235,0.259,0.351,0.391
				c0.629,0.717,1.237,1.449,1.826,2.195c0.116,0.148,0.23,0.297,0.345,0.446c0.594,0.77,1.171,1.551,1.721,2.35
				c0.046,0.067,0.089,0.136,0.134,0.202c0.527,0.775,1.031,1.564,1.516,2.365c0.052,0.084,0.11,0.165,0.161,0.25l11.416-11.417
				C252.769,132.331,252.909,129.418,251.206,127.713z"
            />
            <path
              fill="#137F7B"
              d="M188.444,191.813c-0.07-0.104-0.138-0.211-0.209-0.315c-0.515-0.752-1.054-1.49-1.612-2.215
				c-0.106-0.139-0.213-0.277-0.321-0.415c-0.562-0.715-1.143-1.418-1.745-2.105c-0.098-0.111-0.199-0.221-0.298-0.331
				c-0.638-0.715-1.292-1.419-1.975-2.1c-0.681-0.681-1.385-1.335-2.1-1.973c-0.111-0.1-0.221-0.201-0.333-0.299
				c-0.687-0.602-1.389-1.182-2.105-1.744c-0.138-0.108-0.277-0.216-0.416-0.323c-0.726-0.558-1.463-1.098-2.217-1.613
				c-0.103-0.071-0.208-0.138-0.312-0.207c-0.754-0.507-1.519-0.996-2.298-1.46c-0.044-0.026-0.085-0.056-0.129-0.082l-0.001,0.002
				l-3.376,10.784l-1.327,4.238l-0.236,0.752l-0.568,1.814c0.041,0.024,0.084,0.051,0.126,0.075c0.11,0.064,0.218,0.13,0.331,0.197
				c0.213,0.127,0.424,0.258,0.638,0.395c0.12,0.076,0.236,0.152,0.355,0.231c0.215,0.143,0.423,0.293,0.63,0.447
				c0.102,0.076,0.205,0.148,0.303,0.226c0.296,0.233,0.582,0.476,0.838,0.733c0.258,0.257,0.501,0.543,0.735,0.84
				c0.076,0.097,0.147,0.197,0.221,0.297c0.156,0.21,0.308,0.421,0.454,0.64c0.078,0.116,0.152,0.23,0.226,0.348
				c0.14,0.219,0.273,0.435,0.403,0.652c0.063,0.106,0.125,0.208,0.187,0.313c0.025,0.043,0.053,0.088,0.078,0.131l17.588-5.51
				l0.002-0.001v0c-0.028-0.047-0.061-0.092-0.088-0.139C189.433,193.323,188.948,192.563,188.444,191.813z"
            />
          </g>
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M222.375,204.473c-2.214,0-4.008,1.794-4.008,4.008v58.377c0,2.279-1.855,4.133-4.135,4.133H53.979
				c-2.281,0-4.136-1.854-4.136-4.133V95.95c0-2.214-1.794-4.008-4.008-4.008s-4.008,1.794-4.008,4.008v170.909
				c0,6.7,5.452,12.15,12.153,12.15h160.252c6.701,0,12.152-5.45,12.152-12.15v-58.377
				C226.384,206.268,224.589,204.473,222.375,204.473z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M180.233,228.668c1.422,0,2.575-1.153,2.575-2.575s-1.153-2.575-2.575-2.575H84.967
				c-1.422,0-2.575,1.153-2.575,2.575s1.153,2.575,2.575,2.575H180.233z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M253.709,125.211l-12.307-12.304c-1.435-1.436-3.375-2.227-5.461-2.227c-2.222,0-4.422,0.925-6.037,2.538
				l-3.514,3.514V45.225c0-6.706-5.456-12.162-12.162-12.162h-51.41c-0.089,0-0.172,0.021-0.26,0.026
				c-1.365-6.246-7.046-10.665-14.279-10.665h-28.622c-7.225,0-12.901,4.408-14.275,10.641H53.979
				c-6.701,0-12.153,5.452-12.153,12.153v35.867c0,2.214,1.794,4.008,4.008,4.008s4.008-1.794,4.008-4.008V45.219
				c0-2.281,1.855-4.137,4.136-4.137h51.398c1.368,6.242,7.048,10.657,14.28,10.657h28.622c7.241,0,12.928-4.428,14.285-10.686
				c0.085,0.006,0.167,0.026,0.254,0.026h51.41c2.286,0,4.145,1.859,4.145,4.145v79.524l-1.528,1.528l-44.166,44.166
				c-0.352-0.181-0.745-0.292-1.168-0.292H74.234c-1.422,0-2.575,1.153-2.575,2.575s1.153,2.575,2.575,2.575h94.849l-3.745,11.96
				H74.234c-1.422,0-2.575,1.153-2.575,2.575s1.153,2.575,2.575,2.575h89.491l-0.661,2.112l-0.001,0.004l0,0l-3.342,10.135
				c-0.044,0.134-0.063,0.271-0.074,0.407h-74.68c-1.422,0-2.575,1.153-2.575,2.575s1.153,2.575,2.575,2.575h95.267
				c1.422,0,2.575-1.153,2.575-2.575s-1.153-2.575-2.575-2.575h-12.738l4.593-1.515h0l20.645-6.468
				c0.27-0.085,0.516-0.233,0.717-0.433l0.297-0.294c0,0,0-0.001,0.001-0.001c0.001-0.001,0.002-0.002,0.004-0.003l24.622-24.622
				v21.506c0,2.214,1.794,4.008,4.008,4.008s4.008-1.794,4.008-4.008v-25.444c0-1.125-0.466-2.139-1.213-2.866l15.158-15.158
				l13.058-13.059C256.65,133.456,256.792,128.298,253.709,125.211z M157.728,38.14c0,5.548-4.753,8.451-9.449,8.451h-28.622
				c-4.697,0-9.451-2.903-9.451-8.451v-2.115c0-5.548,4.754-8.451,9.451-8.451h28.622c4.695,0,9.449,2.903,9.449,8.451V38.14z
				 M239.475,145.624c-0.051-0.085-0.109-0.166-0.161-0.25c-0.485-0.801-0.989-1.59-1.516-2.365
				c-0.045-0.066-0.088-0.135-0.134-0.202c-0.55-0.799-1.127-1.58-1.721-2.35c-0.115-0.149-0.229-0.298-0.345-0.446
				c-0.589-0.746-1.197-1.479-1.826-2.195c-0.115-0.131-0.234-0.26-0.351-0.391c-0.665-0.742-1.345-1.474-2.053-2.182
				c-0.708-0.708-1.44-1.389-2.183-2.055c-0.127-0.114-0.253-0.229-0.381-0.342c-0.718-0.631-1.452-1.24-2.2-1.83
				c-0.146-0.115-0.293-0.228-0.44-0.341c-0.403-0.312-0.811-0.618-1.223-0.917c-0.375-0.272-0.749-0.544-1.13-0.807
				c-0.067-0.046-0.137-0.089-0.204-0.135c-0.776-0.529-1.567-1.034-2.37-1.521c-0.083-0.05-0.163-0.108-0.246-0.158l5.4-5.4
				l6.016-6.016c0.956-0.955,2.244-1.502,3.535-1.502c1.141,0,2.191,0.422,2.958,1.19l12.307,12.304
				c1.704,1.706,1.563,4.618-0.314,6.494L239.475,145.624z M189.982,194.237l-0.002,0.001l-17.588,5.51
				c-0.025-0.043-0.053-0.088-0.078-0.131c-0.06-0.104-0.124-0.208-0.187-0.313c-0.129-0.216-0.264-0.434-0.403-0.652
				c-0.074-0.117-0.149-0.232-0.226-0.348c-0.145-0.218-0.298-0.43-0.454-0.64c-0.074-0.099-0.145-0.201-0.221-0.297
				c-0.234-0.297-0.478-0.583-0.735-0.84c-0.257-0.257-0.542-0.5-0.838-0.733c-0.098-0.078-0.202-0.151-0.303-0.226
				c-0.207-0.153-0.416-0.304-0.63-0.447c-0.118-0.079-0.236-0.155-0.355-0.231c-0.213-0.136-0.426-0.268-0.638-0.395
				c-0.111-0.066-0.222-0.133-0.331-0.197c-0.042-0.024-0.085-0.051-0.126-0.075l0.568-1.814l0.236-0.752l1.327-4.238l3.376-10.784
				l0.001-0.002c0.043,0.026,0.085,0.056,0.129,0.082c0.779,0.464,1.544,0.953,2.298,1.46c0.104,0.069,0.209,0.136,0.312,0.207
				c0.753,0.515,1.491,1.055,2.217,1.613c0.139,0.107,0.278,0.214,0.416,0.323c0.716,0.562,1.418,1.142,2.105,1.744
				c0.112,0.099,0.222,0.2,0.333,0.299c0.715,0.638,1.418,1.292,2.1,1.973c0.682,0.681,1.336,1.385,1.975,2.1
				c0.099,0.11,0.2,0.22,0.298,0.331c0.602,0.687,1.183,1.39,1.745,2.105c0.108,0.138,0.215,0.276,0.321,0.415
				c0.558,0.725,1.097,1.462,1.612,2.215c0.071,0.104,0.139,0.21,0.209,0.315c0.504,0.749,0.989,1.51,1.45,2.284
				C189.921,194.145,189.954,194.189,189.982,194.237L189.982,194.237z M221.079,164.02c-1.159,0.401-2.068,1.31-2.469,2.469
				l-25.717,25.717c-0.048-0.081-0.104-0.158-0.153-0.238c-0.489-0.808-0.997-1.604-1.529-2.386
				c-0.042-0.062-0.082-0.125-0.124-0.187c-0.551-0.802-1.131-1.585-1.727-2.358c-0.112-0.146-0.223-0.291-0.337-0.436
				c-0.59-0.749-1.2-1.483-1.832-2.202c-0.114-0.129-0.23-0.256-0.346-0.385c-0.666-0.744-1.348-1.476-2.058-2.186
				c-0.709-0.708-1.44-1.389-2.184-2.054c-0.13-0.116-0.259-0.235-0.39-0.35c-0.717-0.63-1.45-1.238-2.197-1.827
				c-0.147-0.116-0.297-0.23-0.446-0.345c-0.77-0.594-1.551-1.171-2.35-1.721c-0.066-0.045-0.135-0.088-0.202-0.134
				c-0.775-0.527-1.564-1.031-2.365-1.517c-0.084-0.051-0.165-0.109-0.249-0.16l43.994-43.993c0.046,0.026,0.093,0.051,0.139,0.078
				c0.831,0.481,1.646,0.988,2.448,1.517c0.137,0.09,0.273,0.18,0.409,0.272c0.792,0.533,1.569,1.088,2.331,1.667
				c0.124,0.094,0.246,0.19,0.369,0.286c0.771,0.597,1.527,1.214,2.264,1.858c0.084,0.073,0.166,0.149,0.249,0.223
				c0.771,0.683,1.526,1.384,2.257,2.116c0.734,0.734,1.438,1.492,2.122,2.265c0.073,0.082,0.147,0.162,0.22,0.245
				c0.646,0.739,1.265,1.499,1.864,2.271c0.094,0.121,0.188,0.241,0.281,0.362c0.581,0.763,1.137,1.542,1.671,2.336
				c0.09,0.134,0.179,0.268,0.268,0.403c0.529,0.802,1.036,1.617,1.516,2.448c0.026,0.046,0.052,0.093,0.078,0.139L221.079,164.02z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M228.878,144.764l-37.256,37.255c-0.691,0.691-0.691,1.812,0,2.503c0.346,0.346,0.798,0.518,1.251,0.518
				c0.453,0,0.906-0.173,1.251-0.518l37.256-37.255c0.691-0.691,0.691-1.812,0-2.503C230.69,144.073,229.57,144.073,228.878,144.764
				z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconDocumentCreate;
