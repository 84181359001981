/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import PenIcon from "../../../../assets/icons/traffic/toggleable/IconWriting";
import IncomingCallIcon from "../../../../assets/icons/traffic/toggleable/IconIncomingCall";
import HandshakeIcon from "../../../../assets/icons/traffic/toggleable/IconShakeHands";
import howItWorksSectionStyle from "./howItWorksSectionStyle";
import Container from "../../../Container/Container";
import Grid from "@mui/material/Grid";
import TextContainer from "../../../Container/TextContainer";
import Typography from "@mui/material/Typography";
import GradientPaper from "../../../GradientComponents/GradientPaper";
import { Box } from "@mui/material";
import theme from "../../../../theme/Theme";

const HowItWorksSection = () => {
  return (
    <>
      <Container sx={howItWorksSectionStyle.centered}>
        <Box sx={howItWorksSectionStyle.scrollToAnchor} id="so-funktioniert-es" />
        <Typography variant={"h2"}>So einfach funktioniert es</Typography>
      </Container>
      <div>
        <TextContainer sx={howItWorksSectionStyle.centered}>
          <Grid container>
            <Grid item xs={12}>
              <GradientPaper sx={howItWorksSectionStyle.paper}>
                <Grid container sx={howItWorksSectionStyle.advantageContainer}>
                  <Grid item xs={12} sm={4}>
                    <PenIcon sx={howItWorksSectionStyle.icon(theme)} />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant={"h4"}>1. Unterhalt berechnen</Typography>
                    <p>Sie erstellen sich in nur 5 Minuten Ihr persönliches Dokument zum Thema Unterhalt.</p>
                  </Grid>
                </Grid>
                <Grid container sx={howItWorksSectionStyle.advantageContainer}>
                  <Grid item xs={12} sm={8}>
                    <Typography variant={"h4"}>2. Termin für Erstberatung</Typography>
                    <p>Wir prüfen Ihren Fall und rufen Sie zur von Ihnen gewünschten Uhrzeit an.</p>
                  </Grid>
                  <Grid item xs={12} sm={4} sx={howItWorksSectionStyle.gridItemReversed(theme)}>
                    <IncomingCallIcon sx={howItWorksSectionStyle.icon(theme)} />
                  </Grid>
                </Grid>
                <Grid container sx={howItWorksSectionStyle.advantageContainer}>
                  <Grid item xs={12} sm={4}>
                    <HandshakeIcon sx={howItWorksSectionStyle.icon(theme)} />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant={"h4"}>3. Weitere Schritte einleiten</Typography>
                    <p>Wir besprechen Ihre Möglichkeiten und Sie entscheiden sich, wie Sie weiter vorgehen möchten.</p>
                  </Grid>
                </Grid>
              </GradientPaper>
            </Grid>
          </Grid>
        </TextContainer>
      </div>
    </>
  );
};

export default HowItWorksSection;
