import { Rating } from "@mui/material";
import React, { useState } from "react";
import FeedbackComment from "./FeedbackComment";
import {CustomerFeedback} from "../../types/Entities/CustomerFeedback";

type FeedbackProps = {
  feedbackEntity: CustomerFeedback;
  saveFeedback: (feedback: Partial<CustomerFeedback>) => void;
};

export default function Feedback({ feedbackEntity, saveFeedback }: FeedbackProps) {
  const [stars, setStars] = useState<number>(feedbackEntity.stars ?? 0);
  const saveStars = (event: React.ChangeEvent<{}>, newStarsValue: number | null) => {
    if (newStarsValue !== null) {
      setStars(newStarsValue);
    }
  };

  return (
    <>
      <Rating name="stars" value={stars} disabled={!!feedbackEntity.feedbackDateTime} onChange={saveStars} />
      <FeedbackComment feedbackEntity={feedbackEntity} stars={stars} saveFeedback={saveFeedback} />
    </>
  );
}
