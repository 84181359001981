import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconMyDocuments = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45">
        <g>
          <path
            style={{ fill: "none" }}
            d="M142.048,259.238c0.196-0.502,0.104-0.766-0.026-0.979c-0.148-0.242-0.314-0.445-0.741-0.445
			c-0.112,0-0.242,0.014-0.393,0.045c-2.527,0.51-6.066,4.445-6.381,6.531c-0.038,0.248,0.04,0.477,0.174,0.676
			C137.624,263.615,141.26,261.254,142.048,259.238z"
          />
          <path
            style={{ fill: "none" }}
            d="M115.441,261.568c9.954-4.686,12.818-8.463,13.623-10.438c0.384-0.943,0.437-1.797,0.157-2.539
			c-0.2-0.098-0.531-0.148-0.961-0.148c-1.983,0-6.1,1.09-9.521,3.709c-2.365,1.811-4.911,4.773-3.606,8.602
			C115.228,261.035,115.332,261.307,115.441,261.568z"
          />
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M78.06,206.086h102.416c0.972,0,1.76-0.789,1.76-1.76c0-0.973-0.788-1.76-1.76-1.76H78.06
				c-0.972,0-1.76,0.787-1.76,1.76C76.3,205.297,77.088,206.086,78.06,206.086z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M78.06,191.061h102.416c0.972,0,1.76-0.789,1.76-1.76c0-0.973-0.788-1.76-1.76-1.76H78.06
				c-0.972,0-1.76,0.787-1.76,1.76C76.3,190.271,77.088,191.061,78.06,191.061z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M172.91,236.168c0-0.973-0.788-1.76-1.76-1.76H87.486c-0.972,0-1.76,0.787-1.76,1.76
				c0,0.971,0.788,1.76,1.76,1.76h83.664C172.122,237.928,172.91,237.139,172.91,236.168z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M171.15,218.205H87.486c-0.972,0-1.76,0.787-1.76,1.76c0,0.971,0.788,1.76,1.76,1.76h83.664
				c0.972,0,1.76-0.789,1.76-1.76C172.91,218.992,172.122,218.205,171.15,218.205z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M246.876,172.932c1.944,0,3.52-1.576,3.52-3.52V36.879c0-5.883-4.785-10.668-10.667-10.668
				h-45.902c-1.201-5.189-5.963-8.85-12.019-8.85h-25.133c-6.056,0-10.819,3.66-12.019,8.85H98.992
				c-5.881,0-10.665,4.785-10.665,10.668v29.779H60.269c-5.881,0-10.665,4.785-10.665,10.668v31.492c0,1.943,1.576,3.52,3.521,3.52
				s3.521-1.576,3.521-3.52V77.326c0-2,1.625-3.627,3.624-3.627h31.578h14.087c1.2,5.189,5.962,8.85,12.019,8.85h25.133
				c6.056,0,10.818-3.66,12.019-8.85h45.903c2,0,3.626,1.627,3.626,3.627v161.348v33.301c0,2-1.626,3.625-3.626,3.625H60.269
				c-1.999,0-3.624-1.625-3.624-3.625V121.873c0-1.945-1.576-3.521-3.521-3.521s-3.521,1.576-3.521,3.521v150.102
				c0,5.881,4.784,10.666,10.665,10.666h140.738c5.882,0,10.667-4.785,10.667-10.666v-29.781h28.055
				c5.882,0,10.667-4.783,10.667-10.664v-49.057c0-1.945-1.576-3.521-3.52-3.521s-3.521,1.576-3.521,3.521v49.057
				c0,1.998-1.626,3.623-3.626,3.623h-28.055V196.15h7.514c0.973,0,1.76-0.789,1.76-1.76c0-0.973-0.788-1.76-1.76-1.76h-7.514
				v-11.18h7.514c0.973,0,1.76-0.789,1.76-1.76c0-0.973-0.788-1.76-1.76-1.76h-7.514v-11.193h7.514c0.973,0,1.76-0.789,1.76-1.76
				c0-0.973-0.788-1.76-1.76-1.76h-7.514v-11.178h7.514c0.973,0,1.76-0.789,1.76-1.76c0-0.973-0.788-1.76-1.76-1.76h-7.514V77.326
				c0-5.883-4.785-10.668-10.667-10.668h-45.903c-1.2-5.189-5.963-8.85-12.019-8.85h-25.133c-6.056,0-10.819,3.66-12.019,8.85
				H95.368V36.879c0-2,1.625-3.627,3.624-3.627h45.665c1.2,5.189,5.963,8.85,12.019,8.85h25.133c6.056,0,10.818-3.66,12.019-8.85
				h45.903c2,0,3.626,1.627,3.626,3.627v132.533C243.356,171.355,244.932,172.932,246.876,172.932z M117.953,61.328h25.133
				c4.076,0,7.252,2.131,8.366,5.33c0.279,0.805,0.435,1.672,0.435,2.594v1.855c0,0.922-0.156,1.789-0.436,2.592
				c-1.114,3.197-4.29,5.33-8.365,5.33h-25.133c-4.076,0-7.251-2.133-8.365-5.33c-0.28-0.803-0.436-1.67-0.436-2.592v-1.855
				c0-0.922,0.155-1.789,0.435-2.594C110.701,63.459,113.876,61.328,117.953,61.328z M190.609,28.805v1.855
				c0,0.922-0.156,1.789-0.436,2.592c-1.114,3.197-4.29,5.33-8.365,5.33h-25.133c-4.076,0-7.251-2.133-8.365-5.33
				c-0.28-0.803-0.436-1.67-0.436-2.592v-1.855c0-0.922,0.155-1.789,0.435-2.594c1.114-3.199,4.29-5.33,8.366-5.33h25.133
				c4.076,0,7.252,2.131,8.366,5.33C190.454,27.016,190.609,27.883,190.609,28.805z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M109.377,266.072c0.363,0.902,1.391,1.342,2.291,0.973c0.805-0.324,1.587-0.65,2.347-0.977
				c2.472,3.154,6.243,4.787,11.009,4.787c2.321,0,4.88-0.387,7.639-1.176c0.366-0.104,0.917-0.301,1.59-0.578
				c3.09,1.502,8.086,1.492,11.783-1.502c1.557-1.258,2.885-2.494,3.952-3.486c0.199-0.186,0.41-0.381,0.62-0.576
				c-0.006,1.209,0.156,2.354,1.241,3.008c1.377,0.832,3.029,0.016,4.603-0.891c0.843-0.484,1.133-1.561,0.647-2.404
				c-0.484-0.842-1.561-1.133-2.403-0.646c-0.208,0.119-0.391,0.219-0.552,0.305c0.001-0.02,0.002-0.041,0.002-0.061
				c0.014-0.396,0.029-0.83,0.029-1.291c0-1.385-0.471-2.291-1.399-2.695c-1.577-0.689-2.859,0.508-5.187,2.674
				c-1.026,0.957-2.305,2.146-3.768,3.328c-1.368,1.109-3.041,1.637-4.617,1.711c2.637-1.621,5.217-3.736,6.122-6.055
				c0.562-1.438,0.458-2.852-0.3-4.094c-1.035-1.693-2.799-2.428-4.836-2.018c-4.111,0.83-8.648,6.033-9.164,9.455
				c-0.133,0.877-0.001,1.734,0.366,2.518c-4.787,1.32-10.546,1.793-14.03-1.826c8.27-3.943,13.293-8.002,14.961-12.096
				c0.769-1.887,0.792-3.758,0.067-5.412c-1.317-3.008-6.313-2.313-10.189-0.805c-6.916,2.688-12.813,8.568-10.401,15.646
				c0.131,0.385,0.272,0.756,0.426,1.115c-0.604,0.258-1.229,0.516-1.876,0.777C109.449,264.145,109.013,265.172,109.377,266.072z
				 M140.888,257.859c0.151-0.031,0.281-0.045,0.393-0.045c0.427,0,0.593,0.203,0.741,0.445c0.129,0.213,0.222,0.477,0.026,0.979
				c-0.788,2.016-4.425,4.377-7.367,5.828c-0.134-0.199-0.212-0.428-0.174-0.676C134.822,262.305,138.361,258.369,140.888,257.859z
				 M118.739,252.152c3.421-2.619,7.537-3.709,9.521-3.709c0.43,0,0.761,0.051,0.961,0.148c0.28,0.742,0.228,1.596-0.157,2.539
				c-0.804,1.975-3.668,5.752-13.623,10.438c-0.109-0.262-0.213-0.533-0.309-0.814C113.828,256.926,116.374,253.963,118.739,252.152
				z"
            />
          </g>
          <g>
            <path
              style={{ fill: "#137F7B" }}
              d="M117.953,79.029h25.133c4.076,0,7.251-2.133,8.365-5.33c0.28-0.803,0.436-1.67,0.436-2.592v-1.855
				c0-0.922-0.156-1.789-0.435-2.594c-1.114-3.199-4.29-5.33-8.366-5.33h-25.133c-4.076,0-7.252,2.131-8.366,5.33
				c-0.28,0.805-0.435,1.672-0.435,2.594v1.855c0,0.922,0.156,1.789,0.436,2.592C110.701,76.896,113.876,79.029,117.953,79.029z"
            />
            <path
              style={{ fill: "#137F7B" }}
              d="M190.174,26.211c-1.114-3.199-4.29-5.33-8.366-5.33h-25.133c-4.076,0-7.252,2.131-8.366,5.33
				c-0.28,0.805-0.435,1.672-0.435,2.594v1.855c0,0.922,0.156,1.789,0.436,2.592c1.114,3.197,4.29,5.33,8.365,5.33h25.133
				c4.076,0,7.251-2.133,8.365-5.33c0.28-0.803,0.436-1.67,0.436-2.592v-1.855C190.609,27.883,190.454,27.016,190.174,26.211z"
            />
            <polygon
              style={{ fill: "#137F7B" }}
              points="136.445,106.193 124.832,106.193 124.832,125.391 105.634,125.391 105.634,137.006
				124.832,137.006 124.832,156.205 136.445,156.205 136.445,137.006 155.646,137.006 155.646,125.391 136.445,125.391 			"
            />
          </g>
        </g>
      </g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24"></g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconMyDocuments;
