import React from "react";
import serviceWorldStyle from "./serviceWorldStyle";
import EmailConfirmationBox from "./Common/EmailConfirmationBox";
import ServiceWorldMyCases from "./MyCases/ServiceWorldMyCases";
import ServiceWorldNewCase from "./ServiceWorldNewCase";
import ActiveCaseInquiry from "./MyCases/ActiveCaseInquiry";
import { getCaseLink } from "../../services/productService";
import { Box } from "@mui/material";
import { MinimalCase } from "../../types/Entities/MinimalCase";

const ServiceWorld = () => {
  return (
    <Box sx={serviceWorldStyle.contentContainer}>
      <EmailConfirmationBox />
      <ServiceWorldMyCases NotificationComponent={ActiveCaseInquiry} listItemButtonConfig={{ getLink: (minimalCase: MinimalCase) => getCaseLink(minimalCase) }} />
      <ServiceWorldNewCase />
    </Box>
  );
};

export default ServiceWorld;
