import { modalContainer, scrollToAnchor } from "../../../../theme/commonStyle";
import { Theme } from "@mui/material";

const divorceCostBoxStyle = {
  modalContainer,
  scrollToAnchor,
  costHintText: {
    textAlign: "justify",
    fontSize: 14,
  },
  costRealLabel: {
    fontWeight: 500,
    paddingBottom: 0,
  },
  costRealValue: {
    fontWeight: 500,
    paddingBottom: 0,
    textAlign: "right",
  },
  costButton: {
    paddingLeft: "8px",
    paddingRight: "8px",
    marginTop: ".8rem",
  },
  costDisclaimerText: {
    fontSize: 9,
    lineHeight: 1.5,
  },
  xsSpace: (theme: Theme) => ({
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem",
      paddingTop: "1rem",
    },
  }),
  costEstimateLabel: {
    fontSize: "1.25rem",
    fontWeight: 400,
    textAlign: "left",
    marginBottom: ".5rem",
  },
  costEstimateLabelSmall: {
    fontSize: "1.25rem",
  },
  costEstimateValue: {
    fontSize: "1.25rem",
    fontWeight: 500,
  },
  costEstimateValueSmall: {
    fontSize: "1.125rem",
    fontWeight: 500,
  },
  infoIconContainer: {
    textAlign: "right",
    padding: "5px",
  },
  info: {
    height: "20px",
    width: "20px",
    cursor: "pointer",
  },
  primary: {
    color: "primary.main",
  },
  loadingContainer: {
    margin: "50px 230px",
  },
  content: {
    position: "relative",
    padding: "1.5rem",
    height: "100%",
    width: "100%",
  },
  boxGrid: {
    height: "100%",
  },
  boxMain: {
    flex: "1 0 auto",
    paddingTop: "1.5rem",
  },
  boxFooter: {
    flexShrink: 0,
  },
  paymentHint: {
    fontWeight: 500,
    textAlign: "center",
  },
  hintBox: {
    textAlign: "center",
    border: "1px solid ",
    borderColor: "primary.main",
    borderRadius: "5px",
    padding: "0.5rem",
    marginTop: "1.5rem",
  },
  costRealSub: {
    paddingTop: 0,
    paddingBottom: "20px",
  },
  paymentInfoButton: {
    marginBottom: "25px",
    paddingLeft: 0,
    paddingRight: 0,
  },
  paymentHintSub: {
    textAlign: "center",
  },
  divider: {
    backgroundColor: "#000000",
    margin: "1rem 0",
  },
  sum: {
    color: "primary.main",
    marginBottom: "2rem",
  },
};

export default divorceCostBoxStyle;
