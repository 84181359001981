import React, { useState } from "react";
import { MinimalCase } from "../../../types/Entities/MinimalCase";
import Paper from "@mui/material/Paper";
import { Conversation } from "../../../types/Entities/Messaging/Conversation";
import { getProductData } from "../../../services/productService";
import { Box, Grid, Typography } from "@mui/material";
import MessageFormEditor from "../MessageForm/MessageFormEditor";
import { convertToRaw, EditorState } from "draft-js";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { getNewMessageLink } from "../../../services/messagingService";
import messageCenterMessageReplyStyle from "./messageCenterMessageReplyStyle";

type MessageCenterMessageReplyProps = {
  product: MinimalCase;
  conversation: Conversation;
};

export default function MessageCenterMessageReply({ product, conversation }: MessageCenterMessageReplyProps) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorStateLengthError, setEditorStateLengthError] = useState("");
  const currentEditorStatePlainTextLength = editorState.getCurrentContent().getPlainText("").length;
  const extendedMinimalCase = {
    ...product,
    "@id": getProductData(product.name, "apiUri") + "/" + product.id,
    isCustomerDataCompleted: true,
  };

  if (conversation.isClosed || !conversation.id) {
    return null;
  }

  function validateEditorField(event: React.MouseEvent<HTMLAnchorElement>) {
    if (currentEditorStatePlainTextLength < 20) {
      event.preventDefault();
      setEditorStateLengthError("Bitte geben Sie mindestens 20 Zeichen ein");
      return;
    }
    if (currentEditorStatePlainTextLength > 5000) {
      event.preventDefault();
      setEditorStateLengthError("Bitte geben Sie maximal 5000 Zeichen ein");
      return;
    }
  }

  return (
    <Box>
      <Paper elevation={4} sx={messageCenterMessageReplyStyle.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant={"h4"} sx={{ textAlign: "center" }}>
              Antwort verfassen
            </Typography>
          </Grid>

          <MessageFormEditor
            editorState={editorState}
            setEditorState={setEditorState}
            currentEditorStatePlainTextLength={currentEditorStatePlainTextLength}
            editorStateLengthError={editorStateLengthError}
          />

          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6}>
            <Button
              component={Link}
              fullWidth
              variant={"contained"}
              to={getNewMessageLink(extendedMinimalCase.name, extendedMinimalCase.id, conversation.id)}
              state={{ editor: convertToRaw(editorState.getCurrentContent()) }}
              onClick={validateEditorField}
            >
              Weiter
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
