import React from "react";
import Grid from "@mui/material/Grid";
import CheckIcon from "../../../assets/icons/common/toggleable/IconCheck";
import CrossIcon from "../../../assets/icons/common/toggleable/IconClose";
import MoneyIcon from "../../../assets/icons/contract/toggleable/IconMoneyEnforce";
import GoodsIcon from "../../../assets/icons/contract/toggleable/IconGoodsEnforce";
import MoneyAndGoodsSvg from "../../../assets/icons/contract/toggleable/IconMoneyAndGoodsEnforce";
import QuestionMarkIcon from "../../../assets/icons/common/toggleable/IconQuestionmark";
import InputAdornment from "@mui/material/InputAdornment";
import { dateFieldDefault, isIban, isMoney, pastOrSameDateDefault, requiredValidator } from "../../../services/validationRules";
import { useForm } from "../../../provider/Form/FormProvider";
import ValidatorTextField from "../../FormFields/ValidatorTextField";
import Group from "../../Wizard/Group";
import FormLegend from "../Common/FormLegend/FormLegend";
import { IbanInput } from "../../FormFields/MaskedInputs/IbanInput";
import ValidatorDateField from "../../FormFields/ValidatorDateField";
import { translate } from "../../../services/translations/translations";
import wizardStyle from "../wizardStyle";

const ContractBaseEnforceClaim = () => {
  const { values } = useForm();

  return (
    <>
      <Grid item xs={12}>
        <Group
          type="radio"
          name="subjectOfDispute"
          question={translate("rentalContract.subjectOfDispute.question", values)}
          options={[
            {
              labelText: translate("rentalContract.subjectOfDispute.options.money.label", values),
              labelIcon: <MoneyIcon />,
              labelIconActive: <MoneyIcon active />,
              value: "money",
            },
            {
              labelText: translate("rentalContract.subjectOfDispute.options.goods.label", values),
              labelIcon: <GoodsIcon />,
              labelIconActive: <GoodsIcon active />,
              value: "goods",
            },
            {
              labelText: translate("rentalContract.subjectOfDispute.options.moneyAndGoods.label", values),
              labelIcon: <MoneyAndGoodsSvg sx={{ width: 300 }} />,
              labelIconActive: <MoneyAndGoodsSvg sx={{ width: 300 }} active />,
              value: "moneyAndGoods",
            },
            {
              labelText: translate("rentalContract.subjectOfDispute.options.other.label", values),
              labelIcon: <QuestionMarkIcon />,
              labelIconActive: <QuestionMarkIcon active />,
              value: "other",
            },
          ]}
          validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
        />
      </Grid>
      {(values.subjectOfDispute === "money" || values.subjectOfDispute === "moneyAndGoods") && (
        <>
          <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
            <FormLegend>Wie viel Geld möchten Sie einfordern?</FormLegend>
          </Grid>
          <Grid item xs={12}>
            <ValidatorTextField
              label="Forderung in €"
              name="claimedAmountClient"
              validators={[
                {
                  validator: isMoney,
                  message: "Bitte geben Sie den Wert in Euro und Cent ein - zum Beispiel 136,15 oder 85,00",
                },
              ]}
              helperText="Bitte geben Sie den Wert in Euro und Cent ein - zum Beispiel 136,15 oder 85,00. Wenn Sie den Betrag noch nicht genau wissen, lassen Sie das Feld gerne einfach leer"
            />
          </Grid>
          <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
            <FormLegend>Wie lautet Ihre Bankverbindung, auf die der geforderte Betrag überwiesen werden soll?</FormLegend>
          </Grid>
          <Grid item xs={12}>
            <ValidatorTextField
              label="IBAN für Rückerstattung"
              name="clientBankData.iban"
              validators={[
                {
                  validator: isIban,
                  message: "Die eingegebene IBAN ist ungültig",
                },
              ]}
              InputProps={{
                startAdornment: <InputAdornment position="start">DE</InputAdornment>,
                inputComponent: IbanInput,
              }}
              helperText={"Wenn Sie die IBAN gerade nicht wissen, lassen Sie das Feld gerne einfach leer."}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Group
          type="radio"
          name="clientHasAlreadyClaimed"
          question="Haben Sie bereits schriftlich eine Forderung an Ihren Vermieter gestellt?"
          options={[
            {
              labelText: "Ja, ich habe bereits etwas eingefordert.",
              labelIcon: <CheckIcon />,
              labelIconActive: <CheckIcon active />,
              value: "yes",
            },
            {
              labelText: "Nein, ich habe bisher nichts eingefordert.",
              labelIcon: <CrossIcon />,
              labelIconActive: <CrossIcon active />,
              value: "no",
            },
          ]}
          validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
        />
      </Grid>
      {values.clientHasAlreadyClaimed === "yes" && (
        <>
          <Grid item xs={12} sx={wizardStyle.gridTopSpaced}>
            <FormLegend>Wann haben Sie schriftlich etwas eingefordert?</FormLegend>
          </Grid>
          <Grid item xs={12}>
            <ValidatorDateField
              name="claimedDateClient"
              label="Datum des Forderungsschreibens"
              validators={[requiredValidator("Bitte geben Sie das Datum an"), dateFieldDefault, pastOrSameDateDefault]}
            />
          </Grid>
          <Grid item xs={12}>
            <ValidatorDateField
              name="claimedDeadlineClient"
              label="Frist im Forderungsschreiben "
              validators={[dateFieldDefault]}
              helperText={"Wenn Sie keine Frist gesetzt haben, dann lassen Sie das Feld einfach leer."}
            />
          </Grid>
        </>
      )}
    </>
  );
};
export default ContractBaseEnforceClaim;
