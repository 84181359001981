import { Box, Theme } from "@mui/material";
import React, { ReactElement } from "react";
import { SxProps } from "@mui/system";

export type IconWrapperProps = {
  icon: string | React.ComponentType<{ sx?: SxProps<Theme> }> | ReactElement<{ sx?: SxProps<Theme>; alt?: string }>;
  sx?: SxProps<Theme>;
  alt?: string;
};

const IconWrapper = ({ icon, sx = {}, alt = "" }: IconWrapperProps) => {
  if (typeof icon === "string") {
    return <Box component={"img"} src={icon} sx={sx} alt={alt} />;
  }

  if (React.isValidElement(icon)) {
    return React.cloneElement(icon, { sx: { ...sx }, alt });
  }

  const IconComponent = icon;
  return <IconComponent sx={sx} />;
};

export default IconWrapper;
