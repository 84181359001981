import React, { useState } from "react";
import Layout from "../../../Layouts/Layout";
import HintBox from "../../../../components/Box/HintBox/HintBox";
import Container from "../../../../components/Container/Container";
import LegalbirdLoader from "../../../../components/ContentLoader/LegalbirdLoader";
import { Link, Navigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { STATUS_EXTRAJUDICIAL_REACTION_RECEIVED } from "../../../../services/contractLaw/leadStatus";
import { getCaseLink, getProductData, getProductNameBy } from "../../../../services/productService";
import { FormProvider } from "../../../../provider/Form/FormProvider";
import _cloneDeep from "lodash/cloneDeep";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchCollection, fetchResource, queryKeys, updateResource } from "../../../../services/reactQuery/reactQueryService";
import useProductParams from "../../../../hooks/useProductParams";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";
import FeedbackFormTenancyLaw from "../../../../components/Form/TenancyLaw/FeedbackFormTenancyLaw/FeedbackFormTenancyLaw";
import { InquiryToCustomerStatus } from "../../../../types/Entities/InquiryToCustomer";

const FeedbackPageTenancyLaw = () => {
  const { productName, productId } = useProductParams();
  const productClassName = getProductNameBy("urlPartShort", productName);
  const productApiUri = getProductData(productClassName, "apiUri");
  const { data: product, isLoading } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const queryClient = useQueryClient();
  const updateMutation = useMutation(updateResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, variables.id), data),
  });

  const inquiryFilters = {
    product: productClassName,
    identifier: "customerFeedbackInquiry",
    productId: productId,
    "status[0]": InquiryToCustomerStatus.STATUS_OPEN,
    "status[1]": InquiryToCustomerStatus.STATUS_OVERDUE,
    "status[2]": InquiryToCustomerStatus.STATUS_IN_PROGRESS,
  };
  const inquiryApiUri = "/inquiry_to_customers";
  const { data: inquiries } = useQuery(queryKeys.collection(inquiryApiUri, inquiryFilters), () => fetchCollection(inquiryApiUri, inquiryFilters), {
    enabled: !!product,
  });

  if (isLoading || !product || !inquiries) {
    return (
      <Layout>
        <Container>
          <Container sx={{ width: 250 }}>
            <LegalbirdLoader />
          </Container>
        </Container>
      </Layout>
    );
  }

  if (!!product.finalClaimStatusClient || product.leadStatus !== STATUS_EXTRAJUDICIAL_REACTION_RECEIVED) {
    return (
      <Layout>
        <Container>
          <HintBox headline={"Vielen Dank für Ihre Rückmeldung!"}>
            Wir haben Ihre Rückmeldung erhalten und werden Ihren Fall entsprechend weiter bearbeiten. Sie werden über das weitere Vorgehen wie gewohnt per
            Nachricht und Ihre Servicewelt informiert.
            <Button component={Link} to={getCaseLink(product)} variant={"text"} color={"primary"}>
              Zurück zum Fall
            </Button>
          </HintBox>
        </Container>
      </Layout>
    );
  }

  const currentInquiryToCustomer = inquiries[0];

  if (!currentInquiryToCustomer || currentInquiryToCustomer.identifier !== "customerFeedbackInquiry") {
    return <Navigate to={getCaseLink(product)} />;
  }

  const getHintText = (product: AbstractCase) => {
    switch (product.caseCategory) {
      case "enforceClaim":
        switch (product.extrajudicialReaction) {
          case "noReaction":
            return (
              <p>
                <strong>Der Vermieter hat leider nicht auf unser Schreiben reagiert.</strong>
                <br />
                <br />
                Bitte geben Sie uns hier eine Rückmeldung darüber, ob Sie zwischenzeitlich etwas von dem Vermieter gehört haben und wie weiter vorgegangen
                werden soll.
              </p>
            );
          case "fullyAgreed":
            return (
              <p>
                <strong>Der Vermieter hat uns mitgeteilt, dass er Ihrer Forderung vollständig nachkommen wird.</strong>
                <br />
                <br />
                Bitte geben Sie uns hier eine Rückmeldung darüber, ob die Forderung erfüllt wurde und der Fall für Sie somit abgeschlossen ist.
              </p>
            );
          case "partiallyAgreed":
            return (
              <p>
                <strong>Der Vermieter hat uns mitgeteilt, dass er Ihrer Forderung teilweise nachkommen wird.</strong>
                <br />
                <br />
                Bitte geben Sie uns hier eine Rückmeldung darüber, ob Sie damit einverstanden sind und der Fall für Sie somit abgeschlossen ist.
              </p>
            );
          case "disagreed":
            return (
              <p>
                <strong>Leider hat uns der Vermieter mitgeteilt, dass er Ihrer Forderung nicht nachkommen wird.</strong>
                <br />
                <br />
                Bitte geben Sie uns hier eine Rückmeldung darüber, wie Sie weiter vorgehen möchten.
              </p>
            );
          default:
            return "";
        }
      case "defendClaim":
        switch (product.extrajudicialReaction) {
          case "noReaction":
            return (
              <p>
                <strong>Der Vermieter hat bisher nicht auf unser Schreiben reagiert.</strong>
                <br />
                <br />
                Bitte geben Sie uns hier eine Rückmeldung darüber, ob Sie zwischenzeitlich etwas von dem Vermieter gehört haben und wie weiter vorgegangen
                werden soll.
              </p>
            );
          case "fullyAgreed":
            return (
              <p>
                <strong>Der Vermieter hat uns mitgeteilt, dass die Forderung gegen Sie vollständig aufgegeben wird.</strong>
                <br />
                <br />
                Bitte geben Sie uns hier eine Rückmeldung darüber, ob der Fall für Sie somit abgeschlossen ist.
              </p>
            );
          case "partiallyAgreed":
            return (
              <p>
                <strong>Der Vermieter hat uns mitgeteilt, dass die Forderung gegen Sie teilweise aufgegeben wird.</strong>
                <br />
                <br />
                Bitte geben Sie uns hier eine Rückmeldung darüber, ob Sie damit einverstanden sind und Sie der teilweisen Forderung nachkommen.
              </p>
            );
          case "disagreed":
            return (
              <p>
                <strong>Leider hat uns der Vermieter mitgeteilt, dass die Forderung gegen Sie aufrecht erhalten wird.</strong>
                <br />
                <br />
                Bitte geben Sie uns hier eine Rückmeldung darüber, wie Sie weiter vorgehen möchten.
              </p>
            );
          default:
            return "";
        }
      default:
        return "";
    }
  };

  const initialValues = {
    reactionReceived: null,
    clientAgrees: null,
    finalClaimStatusClient: null,
    finalProcessFeedbackClient: null,
    additionalCustomerNote: "",
  };

  const handleSubmit = async ({ values }: { values: AbstractCase }) => {
    setIsLoadingInternal(true);
    const updateData = _cloneDeep(values);
    if (updateData.reactionReceived === "no") {
      updateData.finalClaimStatusClient = "noReaction";
    }
    if (updateData.clientAgrees === "yes") {
      updateData.finalClaimStatusClient = "fullyAgreed";
      updateData.finalProcessFeedbackClient = null;
    }
    if (updateData.additionalCustomerNote === "") {
      updateData.additionalCustomerNote = null;
    }
    await updateMutation.mutateAsync({
      uri: getProductData(product.name, "apiUri"),
      id: product.id,
      data: updateData,
    });
    setIsLoadingInternal(false);
  };

  return (
    <Layout backgroundType={"serviceworld"}>
      <Container>
        <HintBox headline={"Ihre Rückmeldung wird benötigt"}>{getHintText(product)}</HintBox>
        <FormProvider initialValues={initialValues} submitAction={handleSubmit}>
          <FeedbackFormTenancyLaw product={product} isLoading={isLoadingInternal} />
        </FormProvider>
      </Container>
    </Layout>
  );
};

export default FeedbackPageTenancyLaw;
