import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import { FormProvider } from "../../../provider/Form/FormProvider";
import { convertFromRaw, EditorState } from "draft-js";
import { useLocation, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/reactQuery/reactQueryService";
import { editorStateToHTML } from "../../../services/editorService";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import { Channel } from "../../../types/Entities/Messaging/Channel";
import MessageFormStepInner from "./MessageFormStepInner";

type NoteFormStepProps = {
  product: AbstractCase;
  setCurrentStep: Function;
  setMessageCreateData: Function;
};

export default function MessageFormStep({ setCurrentStep, product, setMessageCreateData }: NoteFormStepProps) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorStateLengthError, setEditorStateLengthError] = useState("");
  const [searchParams] = useSearchParams();
  const currentEditorStatePlainTextLength = editorState.getCurrentContent().getPlainText("").length;

  const location = useLocation();
  const initializationData = location.state;

  const channelFilter = {
    case: product.id,
  };

  const { data: caseChannels, isLoading: isLoadingChannels } = useQuery(queryKeys.collection("/channels", channelFilter), () =>
    fetchCollection("/channels", channelFilter)
  );

  useEffect(() => {
    if (!initializationData || !caseChannels) {
      return;
    }
    onSubmit({ overwriteEditorState: EditorState.createWithContent(convertFromRaw(initializationData.editor)) });
    location.state = null;
  }, [initializationData, caseChannels]);

  const conversationId = searchParams.get("conversationId");
  const isConversationAnswer = !!conversationId;

  const onSubmit = ({ values, overwriteEditorState }: Record<string, any>) => {
    const editorStateToUse = overwriteEditorState || editorState;
    const currentEditorStatePlainTextLengthToUse = editorStateToUse.getCurrentContent().getPlainText("").length;
    setEditorStateLengthError("");

    if (currentEditorStatePlainTextLengthToUse < 20) {
      setEditorStateLengthError("Bitte geben Sie mindestens 20 Zeichen ein");
      return;
    }
    if (currentEditorStatePlainTextLengthToUse > 5000) {
      setEditorStateLengthError("Bitte geben Sie maximal 5000 Zeichen ein");
      return;
    }

    const mainChannel = caseChannels.find((channel: Channel) => channel.type === "all");

    if (!isConversationAnswer && !mainChannel) {
      return;
    }

    const conversationPayload = isConversationAnswer
      ? "/conversations/" + conversationId
      : {
          subject: values.subject,
          type: values.type,
          channel: "/channels/" + mainChannel.id,
        };

    const createMessageData = {
      messageText: editorStateToHTML(editorStateToUse),
      conversation: conversationPayload,
    };

    setMessageCreateData(createMessageData);
    setCurrentStep((currentStep: number) => {
      return currentStep + 1;
    });
  };

  const initialValues = {
    type: product.isCustomerDataCompleted ? "" : "technicalQuestion",
    subject: "",
  };

  if (isLoadingChannels) {
    return <LegalbirdLoader />;
  }

  if (!caseChannels) {
    return <Typography align={"center"}>Es ist ein technischer Fehler aufgetreten, bitte melden Sie sich bei unserem Kundendienst.</Typography>;
  }

  return (
    <FormProvider initialValues={initialValues} submitAction={onSubmit}>
      <MessageFormStepInner
        product={product}
        setEditorState={setEditorState}
        editorState={editorState}
        editorStateLengthError={editorStateLengthError}
        isConversationAnswer={isConversationAnswer}
        currentEditorStatePlainTextLength={currentEditorStatePlainTextLength}
      />
    </FormProvider>
  );
}
