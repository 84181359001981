import React from "react";
import GradientPaper from "../GradientComponents/GradientPaper";
import _map from "lodash/map";
import Teaser from "./Teaser";
import Container from "../Container/Container";
import zipperTeaserStyle from "./zipperTeaserStyle";
import { IconWrapperProps } from "../IconWrapper/IconWrapper";

type ZipperTeaserProps = {
  teaserRows: { subText: string; headline: string; image: IconWrapperProps["icon"] }[];
};

export default function ZipperTeaser({ teaserRows }: ZipperTeaserProps) {
  return (
    <Container>
      <GradientPaper sx={zipperTeaserStyle.paper}>
        {_map(teaserRows, (teaserRow, index) => (
          <Teaser
            key={index}
            subText={teaserRow.subText}
            headline={teaserRow.headline}
            imageLeftHandSide={index % 2 === 0}
            image={teaserRow.image}
            imageAlt={teaserRow.headline}
            variant={"compact"}
          />
        ))}
      </GradientPaper>
    </Container>
  );
}
