import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToggleableIconProps } from "../../../../types/IconProps";
import { getIconColor } from "../../../../services/iconService";

const IconDigitalMedia = ({ active = false, sx = {} }: ToggleableIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 211.89 211.73">
      <g style={{ stroke: getIconColor(active, theme), fill: "none" }}>
        <path style={{ strokeWidth: "5px" }} d="M110.32,89.79c0-11.11-7.87-20.11-17.58-20.11s-17.58,9-17.58,20.11" />
        <path
          style={{ strokeWidth: "5px", strokeLinecap: "round" }}
          d="M61.15,101.33H54c-4.63,0-8.38,4.14-8.38,9.24s3.75,9.25,8.38,9.25H81s5,0,10-.06l8.48-.11h31.43c4.63,0,8.38-4.14,8.38-9.25s-3.75-9.24-8.38-9.24h-8.16"
        />
        <path style={{ strokeWidth: "5px" }} d="M134,100.66c2.42-7.29-.72-15.47-7-18.27s-13.33.84-15.74,8.14" />
        <path style={{ strokeWidth: "5px" }} d="M51.37,100.66c-2.41-7.29.73-15.47,7-18.27s13.34.84,15.75,8.14" />
        <polyline style={{ strokeWidth: "5px", strokeLinecap: "round" }} points="2.5 27.3 2.5 59.9 35.81 59.9 35.81 2.5 2.5 2.5 2.5 16.37" />
        <rect style={{ strokeWidth: "5px" }} x="9.3" y="157.9" width="71.52" height="51.07" />
        <path style={{ fill: getIconColor(active, theme) }} d="M19.13,54.19a2.46,2.46,0,1,0-2.46-2.46h0a2.46,2.46,0,0,0,2.46,2.46" />
        <path style={{ fill: getIconColor(active, theme) }} d="M29.11,175.85a2.46,2.46,0,1,0-2.46-2.46,2.46,2.46,0,0,0,2.46,2.46h0" />
        <path style={{ fill: getIconColor(active, theme) }} d="M29.11,197.39a2.46,2.46,0,1,0-2.46-2.46,2.46,2.46,0,0,0,2.46,2.46h0" />
        <line style={{ strokeWidth: "3px", strokeLinecap: "round", strokeLinejoin: "round" }} x1="8.17" y1="44.31" x2="30.06" y2="44.31" />
        <polyline
          style={{ strokeWidth: "5px", strokeLinecap: "round" }}
          points="144.8 178.33 144.8 209.23 195.87 209.23 195.87 137.71 144.8 137.71 144.8 167.53"
        />
        <path style={{ fill: getIconColor(active, theme) }} d="M170.83,203.06a2.46,2.46,0,1,0-2.46-2.46,2.46,2.46,0,0,0,2.46,2.46h0" />
        <line style={{ strokeWidth: "3px", strokeLinecap: "round", strokeLinejoin: "round" }} x1="151.74" y1="193.49" x2="188.42" y2="193.49" />
        <line style={{ strokeWidth: "3px", strokeLinecap: "round", strokeLinejoin: "round" }} x1="16.81" y1="184.34" x2="73.34" y2="184.34" />
        <line style={{ strokeWidth: "3px", strokeLinecap: "round", strokeLinejoin: "round" }} x1="44.59" y1="200.61" x2="44.59" y2="165.06" />
        <g style={{ clipPath: "url(#a)" }}>
          <polyline
            style={{ strokeWidth: "5px", strokeLinecap: "round" }}
            points="209.39 25.63 209.39 19.02 209.39 2.5 137.87 2.5 137.87 53.57 209.39 53.57 209.39 34.3"
          />
          <path style={{ strokeWidth: "3px", strokeLinecap: "round" }} d="M164.25,64.2l-1,6.74s-.73,4.94,4.27,4.94h7.65" />
          <path style={{ strokeWidth: "3px", strokeLinecap: "round" }} d="M182.13,64.2l1,6.74s.73,4.94-4.27,4.94H171.2" />
        </g>
        <line style={{ strokeWidth: "3px", strokeLinecap: "round", strokeLinejoin: "round" }} x1="145.07" y1="42.04" x2="201.6" y2="42.04" />
        <line style={{ strokeWidth: "3px", strokeLinecap: "round", strokeLinejoin: "round" }} x1="163.04" y1="60" x2="183.46" y2="60" />
        <line style={{ strokeWidth: "5px", strokeLinecap: "round" }} x1="24.38" y1="157.75" x2="24.38" y2="150.75" />
        <path style={{ strokeWidth: "4.1px", strokeLinecap: "round" }} d="M43.87,144.57a19.75,19.75,0,0,0-19.75-19.75,11.62,11.62,0,0,0-3.43.21" />
        <path style={{ strokeWidth: "4.1px", strokeLinecap: "round" }} d="M34.72,145.1a10.63,10.63,0,0,0-21.25,0" />
        <line
          style={{
            strokeWidth: "3px",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeDasharray: "0 6.24",
          }}
          x1="92.19"
          y1="178.95"
          x2="95.3"
          y2="178.95"
        />
        <path
          style={{
            strokeWidth: "3px",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeDasharray: "0 7.3",
          }}
          d="M105.35,177.17a5.72,5.72,0,0,0,1.79-3.11"
        />
        <line
          style={{
            strokeWidth: "3px",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeDasharray: "0 5.72",
          }}
          x1="107.49"
          y1="164.73"
          x2="107.49"
          y2="127.58"
        />
        <path style={{ strokeWidth: "3px", strokeLinecap: "round", strokeLinejoin: "round" }} d="M107.49,124.72h0m0,45.72h0M98.42,179h0M86,179h0" />
        <line
          style={{
            strokeWidth: "3px",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeDasharray: "0 7.01",
          }}
          x1="132.32"
          y1="149.59"
          x2="135.82"
          y2="149.59"
        />
        <path
          style={{
            strokeWidth: "3px",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeDasharray: "0 7.3",
          }}
          d="M125.39,147.81a5.78,5.78,0,0,1-1.79-3.11"
        />
        <line
          style={{
            strokeWidth: "3px",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeDasharray: "0 5.29",
          }}
          x1="123.25"
          y1="135.79"
          x2="123.25"
          y2="122.55"
        />
        <path style={{ strokeWidth: "3px", strokeLinecap: "round", strokeLinejoin: "round" }} d="M123.25,119.9h0m0,21.18h0m9.07,8.5h0m7,0h0" />
        <line
          style={{
            strokeWidth: "3px",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeDasharray: "0 7.01",
          }}
          x1="174.05"
          y1="89.49"
          x2="174.05"
          y2="85.99"
        />
        <path
          style={{
            strokeWidth: "3px",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeDasharray: "0 7.3",
          }}
          d="M172.27,96.42a5.61,5.61,0,0,1-3.11,1.78"
        />
        <line
          style={{
            strokeWidth: "3px",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeDasharray: "0 5.29",
          }}
          x1="160.25"
          y1="98.56"
          x2="147.01"
          y2="98.56"
        />
        <path style={{ strokeWidth: "3px", strokeLinecap: "round", strokeLinejoin: "round" }} d="M144.37,98.57h0m21.17,0h0m8.51-9.07h0m0-7h0" />
        <line
          style={{
            strokeWidth: "3px",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeDasharray: "0 7.47",
          }}
          x1="87.67"
          y1="57.08"
          x2="87.67"
          y2="53.34"
        />
        <line
          style={{
            strokeWidth: "3px",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeDasharray: "0 6.24",
          }}
          x1="87.67"
          y1="43.37"
          x2="87.67"
          y2="40.25"
        />
        <path
          style={{
            strokeWidth: "3px",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeDasharray: "0 7.3",
          }}
          d="M85.9,30.2a5.7,5.7,0,0,0-3.11-1.78"
        />
        <line
          style={{
            strokeWidth: "3px",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeDasharray: "0 6.05",
          }}
          x1="73.12"
          y1="28.06"
          x2="45.91"
          y2="28.06"
        />
        <path style={{ strokeWidth: "3px", strokeLinecap: "round", strokeLinejoin: "round" }} d="M42.89,28.06h0m36.28,0h0m8.5,9.07h0m0,12.47h0m0,14.94h0" />
        <line style={{ strokeWidth: "5px", strokeLinecap: "round" }} x1="114.66" y1="100.63" x2="109.56" y2="100.63" />
        <path style={{ strokeWidth: "4.1px", strokeLinecap: "round" }} d="M14.71,127.17a19.72,19.72,0,0,0-10.35,17.4" />
      </g>
    </SvgIcon>
  );
};

export default IconDigitalMedia;
