import React from "react";
import { Route, Routes } from "react-router-dom";
import MyAccountPage from "../../containers/Pages/Generic/MyAccountPage/MyAccountPage";
import Error404PageService from "../../containers/Pages/Generic/Error404/Error404PageService";
import ServiceWorldPage from "../../containers/Pages/ServiceWorld/ServiceWorldPage";
import DivorcePlannerPage from "../../containers/Pages/Divorce/DivorcePlanner/DivorcePlannerPage";
import ProcessCostSupportFormPage from "../../containers/Pages/ProcessCostSupport/ProcessCostSupportForm/ProcessCostSupportFormPage";
import RevokePage from "../../containers/Pages/Traffic/RevokePage/RevokePage";
import FeedbackPage from "../../containers/Pages/ContractLaw/FeedbackPage/FeedbackPage";
import ServiceWorldCase from "./ServiceWorldCase";
import NewCasePage from "../../containers/Pages/ServiceWorld/NewCasePage";
import RequestDocumentsPageDivorce from "../../containers/Pages/Divorce/RequestDocuments/RequestDocumentsPage";
import RequestDocumentsPageSettlement from "../../containers/Pages/Settlement/RequestDocuments/RequestDocumentsPage";
import RequestDocumentsPageTraffic from "../../containers/Pages/Traffic/RequestDocuments/RequestDocumentsPage";
import ServiceWorldInitialCasePage from "../../containers/Pages/ServiceWorld/ServiceWorldInitialCasePage";
import PowerOfAttorneyPageTraffic from "../../containers/Pages/Traffic/PowerOfAttorney/PowerOfAttorneyPageTraffic";
import PowerOfAttorneyPage from "../../containers/Pages/ContractLaw/PowerOfAttorney/PowerOfAttorneyPage";
import PowerOfAttorneyPageDivorce from "../../containers/Pages/Divorce/PowerOfAttorney/PowerOfAttorneyPageDivorce";
import CalendlyRedirect from "../../components/Calendly/CalendlyRedirect";
import useTracker from "../../services/useTracker";
import SetCaseCompletedPage from "../../containers/Pages/ContractLaw/SetCaseCompletedPage/SetCaseCompletedPage";
import JudicialProcessCustomerFeedback from "../../containers/Pages/Traffic/JudicialProcessCustomerFeedback/JudicialProcessCustomerFeedback";
import MessageCenterPage from "../../containers/Pages/Generic/MessageCenterPage/MessageCenterPage";
import MessageFormPage from "../../containers/Pages/Generic/MessageFormPage/MessageFormPage";
import FeedbackPageTenancyLaw from "../../containers/Pages/TenancyLaw/FeedbackPage/FeedbackPageTenancyLaw";
import SetCaseCompletedPageTenancyLaw from "../../containers/Pages/TenancyLaw/SetCaseCompletedPage/SetCaseCompletedPageTenancyLaw";
import PowerOfAttorneyContractLaw from "../../components/Form/ContractLaw/PowerOfAttorney";
import PowerOfAttorneyRentalContract from "../../components/Form/RentalContract/PowerOfAttorney";
import PowerOfAttorneySettlement from "../../components/Form/Settlement/PowerOfAttorney";
import FlexServiceFeedback from "../../components/Form/ContractLaw/FeedbackForm/FlexServiceFeedback";
import FlexServiceFeedbackConfirm from "../../components/Form/ContractLaw/FeedbackForm/FlexServiceFeedbackConfirm";
import DataLeakPersonalAffectionPage from "../../containers/Pages/ContractLaw/DataLeakPersonalAffectionPage/DataLeakPersonalAffectionPage";
import NextInstanceDecisionPage from "../../containers/Pages/ContractLaw/NextInstanceDecisionPage/NextInstanceDecisionPage";
import UploadFineNoticePage from "../../containers/Pages/Traffic/UploadFineNotice/UploadFineNoticePage";

export default function Serviceworld() {
  useTracker();

  return (
    <Routes>
      <Route path={"verkehrsrecht/ordnungswidrigkeit/:productId/einspruch-ruecknahme"} element={<RevokePage />} />
      <Route path={"verkehrsrecht/ordnungswidrigkeit/:productId/bussgeldbescheid-hochladen"} element={<UploadFineNoticePage />} />
      <Route path={"verkehrsrecht/ordnungswidrigkeit/:productId/rueckmeldung-gerichtliches-verfahren"} element={<JudicialProcessCustomerFeedback />} />
      <Route path={"familienrecht/scheidung/:productId/scheidungsplaner"} element={<DivorcePlannerPage />} />
      <Route path={"familienrecht/scheidung/:productId/vkh-assistent/*"} element={<ProcessCostSupportFormPage />} />
      <Route path={"familienrecht/scheidung/:productId/dokumente"} element={<RequestDocumentsPageDivorce />} />
      <Route path={"arbeitsrecht/kuendigung/:productId/dokumente"} element={<RequestDocumentsPageSettlement />} />
      <Route path={"verkehrsrecht/verkehr/:productId/dokumente"} element={<RequestDocumentsPageTraffic />} />
      <Route path={"vertragsrecht/:productName/:productId/rueckmeldung"} element={<FeedbackPage />} />
      <Route path={"mietrecht/:productName/:productId/rueckmeldung"} element={<FeedbackPageTenancyLaw />} />
      <Route path={"vertragsrecht/:productName/:productId/abschliessen"} element={<SetCaseCompletedPage />} />

      <Route path={"vertragsrecht/:productName/:productId/entscheidung-naechste-instanz"} element={<NextInstanceDecisionPage />} />
      <Route path={"mietrecht/:productName/:productId/entscheidung-naechste-instanz"} element={<NextInstanceDecisionPage />} />
      <Route path={"datenweitergabe-betroffenheit/:productId"} element={<DataLeakPersonalAffectionPage />} />

      <Route path={"mietrecht/:productName/:productId/abschliessen"} element={<SetCaseCompletedPageTenancyLaw />} />
      <Route path={"verkehrsrecht/ordnungswidrigkeit/:productId/vollmacht"} element={<PowerOfAttorneyPageTraffic />} />
      <Route path={"familienrecht/scheidung/:productId/vollmacht"} element={<PowerOfAttorneyPageDivorce />} />
      <Route
        path={"vertragsrecht/:productName/:productId/vollmacht"}
        element={<PowerOfAttorneyPage productCategory={"contractLaw"} PowerOfAttorneyComponent={PowerOfAttorneyContractLaw} />}
      />
      <Route path={"vertragsrecht/:productName/:productId/flex/rueckmeldung"} element={<FlexServiceFeedback />} />
      <Route path={"vertragsrecht/:productName/:productId/flex/rueckmeldung/bestaetigung"} element={<FlexServiceFeedbackConfirm />} />
      <Route
        path={"mietrecht/:productName/:productId/vollmacht"}
        element={<PowerOfAttorneyPage productCategory={"tenancyLaw"} PowerOfAttorneyComponent={PowerOfAttorneyRentalContract} />}
      />
      <Route
        path={"arbeitsrecht/:productName/:productId/vollmacht"}
        element={<PowerOfAttorneyPage productCategory={"employmentLaw"} PowerOfAttorneyComponent={PowerOfAttorneySettlement} />}
      />

      <Route path={"mein-konto"} element={<MyAccountPage />} />
      <Route path={"nachrichten"} element={<MessageCenterPage />} />
      <Route path={"nachrichten/neu"} element={<MessageFormPage />} />
      <Route path={"calendly/:responsibleParty/:appointmentType"} element={<CalendlyRedirect />} />
      <Route path={":productCategory/:productName/antrag"} element={<NewCasePage />} />
      <Route path={":productCategory/:productName/:productId/*"} element={<ServiceWorldCase />} />
      <Route path={":productCategory/:productName/*"} element={<ServiceWorldInitialCasePage />} />
      <Route path={""} element={<ServiceWorldPage />} />
      <Route path={"*"} element={<Error404PageService />} />
    </Routes>
  );
}
