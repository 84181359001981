import React, { useState } from "react";
import uploadFineNoticeStyle from "./uploadFineNoticeStyle";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import IconUpload from "../../../../assets/icons/serviceOverview/IconDocumentUpload";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ContentBoxHead from "../../../../components/Box/ContentBox/ContentBoxHead";
import FileUploadSection from "../../../../components/Form/Common/FileUploadSection/FileUploadSection";
import ButtonLoading from "../../../../components/Button/ButtonLoading";
import ApiClient from "../../../../services/apiClient";
import Layout from "../../../Layouts/Layout";
import TextContainer from "../../../../components/Container/TextContainer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DialogModal from "../../../../components/DialogModal/DialogModal";
import { dateFieldDefault, isTodayOrPastDate, requiredValidator } from "../../../../services/validationRules";
import Container from "../../../../components/Container/Container";
import LegalbirdLoader from "../../../../components/ContentLoader/LegalbirdLoader";
import { getCaseLink, getProductData } from "../../../../services/productService";
import { FormProvider, useForm } from "../../../../provider/Form/FormProvider";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../../../services/reactQuery/reactQueryService";
import useProductParams from "../../../../hooks/useProductParams";
import { Box, IconButton, Stack } from "@mui/material";
import ValidatorDateField from "../../../../components/FormFields/ValidatorDateField";
import useMediaObjectCombinationRequest from "../../../../hooks/useMediaObjectCombinationRequest";
import wizardStyle from "../../../../components/Form/wizardStyle";

const TrafficFineNoticeForm = ({ handleInfoModalOpen, isLoading, submitDisabled }) => {
  const { handleSubmit, values } = useForm();
  return (
    <form onSubmit={handleSubmit}>
      <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
        <ValidatorDateField
          name="fineNoticeReceivedDate"
          label="Bescheid erhalten am"
          validators={[
            requiredValidator("Bitte geben Sie an, wann Sie den Bußgeldbescheid erhalten haben."),
            dateFieldDefault,
            { validator: isTodayOrPastDate, message: "Das Datum darf nicht in der Zukunft liegen." },
          ]}
        />
        <IconButton onClick={handleInfoModalOpen} sx={{ height: "40px", width: "40px", margin: "10px" }}>
          <InfoOutlinedIcon sx={wizardStyle.info} />
        </IconButton>
      </Stack>

      <ButtonLoading
        sx={uploadFineNoticeStyle.button}
        type={"submit"}
        fullWidth
        variant={"contained"}
        color={"primary"}
        isLoading={isLoading}
        disabled={!values.fineNoticeReceivedDate || submitDisabled}
      >
        Dokument hochladen
      </ButtonLoading>
    </form>
  );
};

export default function UploadFineNotice({ traffic }) {
  const [isBlocked, setIsBlocked] = useState(false);
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { productId } = useProductParams();
  const productApiUri = getProductData("traffic", "apiUri");
  const queryClient = useQueryClient();

  let mediaObjectCombinationRequestTypes = ["fineNoticeFiles"];

  const { mediaObjectCombinationRequests, isLoadingMediaObjectCombinationRequest, updateAction, hasAtLeastOneFileOfType } = useMediaObjectCombinationRequest(
    traffic,
    mediaObjectCombinationRequestTypes
  );

  if (isLoadingMediaObjectCombinationRequest) {
    return (
      <Layout product={"traffic"} active={"traffic-overview"}>
        <Container sx={{ width: 250 }}>
          <LegalbirdLoader />
        </Container>
      </Layout>
    );
  }

  if (traffic.fineNoticeReceivedDate) {
    return <Navigate to={getCaseLink(traffic)} />;
  }

  const handleSubmit = async ({ values }) => {
    setIsLoadingInternal(true);
    ApiClient.post(`/generate_finenotice_mediaobject/traffic/${traffic.id}`, {
      body: JSON.stringify({
        fineNoticeReceivedDate: values.fineNoticeReceivedDate,
      }),
    }).then(
      () => queryClient.invalidateQueries(queryKeys.item(productApiUri, productId)),
      () => setIsLoadingInternal(false)
    );
  };

  const handleInfoModalOpen = () => {
    navigate(location.pathname, { state: { infoModalOpen: true } });
  };

  const handleModalClose = () => {
    navigate(-1);
  };

  return (
    <>
      <TextContainer>
        <Paper elevation={4} sx={uploadFineNoticeStyle.container}>
          <Grid container justifyContent={"center"}>
            <Grid item>
              <ContentBoxHead headline={"Bußgeldbescheid an Kundenservice schicken"} Icon={IconUpload} />
            </Grid>
            <Grid item sx={uploadFineNoticeStyle.textBlock}>
              <p>
                Laden Sie hier Ihren Bußgeldbescheid als Bild oder PDF hoch, oder fotografieren Sie ihn einfach ab. Bitte beachten Sie dabei, dass alles gut
                erkennbar und nichts abgeschnitten ist. Benutzen Sie bestenfalls beim Fotografieren den Blitz.
              </p>
            </Grid>
          </Grid>
          <FileUploadSection
            mediaObjectCombinationRequest={mediaObjectCombinationRequests.fineNoticeFiles}
            temporaryMediaObjectsFieldname={"fineNoticeFiles"}
            productEntity={traffic}
            productEntityId={traffic.id}
            product={"traffic"}
            updateAction={updateAction}
            setIsBlocked={setIsBlocked}
            isBlocked={isBlocked}
            fieldName={"fineNoticeFiles"}
            uploadFileLabel={"Datei auswählen"}
          />
          <Box sx={uploadFineNoticeStyle.question}>Wann haben Sie den Bußgeldbescheid erhalten?</Box>
          <FormProvider initialValues={{ fineNoticeReceivedDate: "" }} submitAction={handleSubmit}>
            <TrafficFineNoticeForm
              traffic={traffic}
              handleInfoModalOpen={handleInfoModalOpen}
              isLoading={isLoadingInternal}
              submitDisabled={!hasAtLeastOneFileOfType(mediaObjectCombinationRequests, "fineNoticeFiles")}
            />
          </FormProvider>
        </Paper>
      </TextContainer>
      <DialogModal open={!!(location.state && location.state.infoModalOpen)} onClose={handleModalClose} title={"Datum der Zustellung"}>
        <p>
          Wichtig: Hier geht es darum, wann Ihnen der Brief (meist in gelbem Umschlag) von der Post zugestellt wurde. Auch wenn Sie zum Beispiel den Brief erst
          eine Woche nachdem er im Briefkasten lag geöffnet haben, gilt hier aus rechtlicher Sicht immer das Datum der Zustellung. Dieses Datum ist sehr
          wichtig, denn gegen einen Bußgeldbescheid kann nur innerhalb von zwei Wochen nach Zustellung bei Ihnen Einspruch eingelegt werden. Wird der Einspruch
          nicht innerhalb der Frist eingelegt, ist er unwirksam und wird von der Behörde als unzulässig verworfen.
        </p>
      </DialogModal>
    </>
  );
}
