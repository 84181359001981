import React from "react";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconProps } from "../../../types/IconProps";

const IconMyCases = ({ sx = {} }: IconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon sx={{ ...sx }} viewBox="0 0 300 300">
      <g id="Layer_1"></g>
      <g id="Layer_97"></g>
      <g id="Layer_96"></g>
      <g id="Layer_95"></g>
      <g id="Layer_94"></g>
      <g id="Layer_93"></g>
      <g id="Layer_92"></g>
      <g id="Layer_91"></g>
      <g id="Layer_90"></g>
      <g id="Layer_89"></g>
      <g id="Layer_88"></g>
      <g id="Layer_87"></g>
      <g id="Layer_86"></g>
      <g id="Layer_85"></g>
      <g id="Layer_84"></g>
      <g id="Layer_83"></g>
      <g id="Layer_82"></g>
      <g id="Layer_81"></g>
      <g id="Layer_80"></g>
      <g id="Layer_79"></g>
      <g id="Layer_78"></g>
      <g id="Layer_77"></g>
      <g id="Layer_76"></g>
      <g id="Layer_75"></g>
      <g id="Layer_74"></g>
      <g id="Layer_73"></g>
      <g id="Layer_72"></g>
      <g id="Layer_71"></g>
      <g id="Layer_70"></g>
      <g id="Layer_69"></g>
      <g id="Layer_68"></g>
      <g id="Layer_67"></g>
      <g id="Layer_66"></g>
      <g id="Layer_65"></g>
      <g id="Layer_64"></g>
      <g id="Layer_63"></g>
      <g id="Layer_62"></g>
      <g id="Layer_61"></g>
      <g id="Layer_60"></g>
      <g id="Layer_59"></g>
      <g id="Layer_58"></g>
      <g id="Layer_57"></g>
      <g id="Layer_56"></g>
      <g id="Layer_55"></g>
      <g id="Layer_54"></g>
      <g id="Layer_53"></g>
      <g id="Layer_52"></g>
      <g id="Layer_51"></g>
      <g id="Layer_50"></g>
      <g id="Layer_49"></g>
      <g id="Layer_48"></g>
      <g id="Layer_47"></g>
      <g id="Layer_46"></g>
      <g id="Layer_45"></g>
      <g id="Layer_44"></g>
      <g id="Layer_43"></g>
      <g id="Layer_42"></g>
      <g id="Layer_41"></g>
      <g id="Layer_40"></g>
      <g id="Layer_39"></g>
      <g id="Layer_38"></g>
      <g id="Layer_37"></g>
      <g id="Layer_36"></g>
      <g id="Layer_35"></g>
      <g id="Layer_34"></g>
      <g id="Layer_33"></g>
      <g id="Layer_32"></g>
      <g id="Layer_31"></g>
      <g id="Layer_30"></g>
      <g id="Layer_29"></g>
      <g id="Layer_28"></g>
      <g id="Layer_27"></g>
      <g id="Layer_26"></g>
      <g id="Layer_25"></g>
      <g id="Layer_24">
        <g>
          <g>
            <path
              fill="#137F7B"
              d="M180.615,26.823h-24.181c-3.968,0-7.985,2.453-7.985,7.141v1.787c0,4.687,4.017,7.139,7.985,7.139h24.181
				c3.967,0,7.983-2.452,7.983-7.139v-1.787C188.598,29.276,184.582,26.823,180.615,26.823z"
            />
            <path
              fill="#137F7B"
              d="M80.841,139.171c-0.196,0.253-0.383,0.512-0.556,0.779c-0.882,1.359-1.422,2.852-1.606,4.436v5.83h6.214
				c0.711-5.582,5.481-9.913,11.255-9.913c4.703,0,8.972,2.955,10.623,7.354c0.279,0.744-0.098,1.574-0.842,1.854
				c-0.167,0.063-0.339,0.089-0.509,0.088v2.058h4.528c0-0.001,0-0.002,0-0.003c0-0.796,0.645-1.44,1.44-1.44h39.108
				c0.795,0,1.44,0.645,1.44,1.44c0,0.001,0,0.002,0,0.003h2.95c0-6.26,5.094-11.353,11.355-11.353
				c4.704,0,8.974,2.955,10.625,7.354c0.279,0.744-0.098,1.574-0.842,1.854c-0.167,0.063-0.34,0.089-0.509,0.088v2.058h3.11
				c0-0.583,0.352-1.131,0.93-1.348c0.915-0.349,3.558-1.711,3.66-3.335l-0.001-7.802H80.841z"
            />
          </g>
          <g>
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M243.22,176.272c-1.87,0-3.387,1.517-3.387,3.387v49.32c0,1.933-1.565,3.505-3.488,3.505h-28.05
				l-0.001-35.771h11.667c1.201,0,2.175-0.974,2.175-2.175s-0.974-2.176-2.175-2.176h-11.667l0-11.238h11.668
				c1.201,0,2.175-0.974,2.175-2.175s-0.974-2.176-2.175-2.176h-11.668l0-10.683h11.668c1.201,0,2.175-0.974,2.175-2.175
				s-0.974-2.176-2.175-2.176h-11.668l0-10.106h11.669c1.201,0,2.175-0.975,2.175-2.176s-0.974-2.175-2.175-2.175h-11.669
				l-0.002-66.766c0-5.385-4.373-9.765-9.748-9.765h-43.572c-0.958-5.531-5.855-9.496-12.151-9.496h-24.18
				c-6.296,0-11.193,3.965-12.151,9.496h-9.036V41.731c0-1.927,1.568-3.495,3.495-3.495h43.423
				c1.156,5.273,5.955,9.004,12.065,9.004h24.181c6.117,0,10.921-3.74,12.068-9.024c0.07,0.004,0.137,0.021,0.208,0.021h43.445
				c1.926,0,3.493,1.568,3.493,3.495v125.052c0,1.87,1.517,3.387,3.387,3.387s3.387-1.517,3.387-3.387V41.731
				c0-5.662-4.605-10.269-10.267-10.269h-43.445c-0.073,0-0.141,0.017-0.212,0.021c-1.153-5.277-5.954-9.012-12.065-9.012h-24.181
				c-6.104,0-10.899,3.724-12.061,8.99h-43.427c-5.662,0-10.269,4.606-10.269,10.269v29.022H63.142c-5.375,0-9.748,4.38-9.748,9.765
				v30.296c0,1.59,1.29,2.88,2.88,2.88s2.88-1.29,2.88-2.88V80.518c0-2.208,1.789-4.005,3.988-4.005h43.342
				c0.952,5.538,5.852,9.511,12.154,9.511h24.18c6.302,0,11.202-3.973,12.154-9.511h43.57c2.199,0,3.988,1.797,3.988,4.005
				l0.005,153.597c-0.313,0.514-0.504,1.11-0.504,1.756s0.19,1.243,0.504,1.756l0.001,30.146c0,2.203-1.792,3.995-3.995,3.995
				H63.147c-2.202,0-3.994-1.792-3.994-3.995V123.371c0-1.59-1.29-2.88-2.88-2.88s-2.88,1.29-2.88,2.88v144.402
				c0,5.379,4.375,9.755,9.753,9.755h135.394c5.378,0,9.754-4.376,9.754-9.755l-0.001-28.516h28.05
				c5.658,0,10.262-4.61,10.262-10.278v-49.32C246.606,177.789,245.09,176.272,243.22,176.272z M156.434,26.823h24.181
				c3.967,0,7.983,2.453,7.983,7.141v1.787c0,4.687-4.016,7.139-7.983,7.139h-24.181c-3.968,0-7.985-2.452-7.985-7.139v-1.787
				C148.448,29.276,152.465,26.823,156.434,26.823z M150.801,74.534c0,4.204-3.283,7.14-7.983,7.14h-24.18
				c-4.701,0-7.983-2.936-7.983-7.14v-1.786c0-4.688,4.016-7.14,7.983-7.14h24.18c3.967,0,7.983,2.452,7.983,7.14V74.534z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M180.964,202.761c0-1.201-0.974-2.176-2.175-2.176H80.26c-1.201,0-2.175,0.975-2.175,2.176
				s0.974,2.175,2.175,2.175h98.529C179.99,204.936,180.964,203.962,180.964,202.761z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M178.789,185H80.26c-1.201,0-2.175,0.974-2.175,2.175s0.974,2.176,2.175,2.176h98.529
				c1.201,0,2.175-0.975,2.175-2.176S179.99,185,178.789,185z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M171.989,233.936c0-1.201-0.974-2.176-2.175-2.176H89.329c-1.201,0-2.175,0.975-2.175,2.176
				s0.974,2.175,2.175,2.175h80.485C171.015,236.111,171.989,235.137,171.989,233.936z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M169.814,216.173H89.329c-1.201,0-2.175,0.974-2.175,2.175s0.974,2.176,2.175,2.176h80.485
				c1.201,0,2.175-0.975,2.175-2.176S171.015,216.173,169.814,216.173z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M161.991,111.719c-0.271-0.276-0.641-0.432-1.028-0.432h-35.437c-5.323,0-7.889,2.152-10.934,5.193
				c-2.761,2.763-10.431,9.693-11.96,11.073c-6.722,0.52-19.72,3.045-24.763,10.83c-1.143,1.762-1.836,3.701-2.061,5.766
				c-0.005,0.052-0.008,0.104-0.008,0.156v7.35c0,0.796,0.645,1.44,1.44,1.44h7.654c0.711,5.583,5.481,9.916,11.255,9.916
				c4.713,0,8.983-2.963,10.625-7.373c0.277-0.746-0.102-1.575-0.847-1.853c-0.745-0.276-1.575,0.102-1.852,0.848
				c-1.224,3.289-4.409,5.498-7.925,5.498c-4.462,0-8.121-3.468-8.445-7.849c0.093-0.19,0.15-0.401,0.15-0.628
				s-0.057-0.438-0.15-0.628c0.324-4.379,3.983-7.845,8.445-7.845c3.51,0,6.695,2.204,7.927,5.485
				c0.216,0.577,0.765,0.928,1.345,0.93c0.169,0.001,0.341-0.024,0.509-0.088c0.745-0.279,1.122-1.109,0.842-1.854
				c-1.651-4.398-5.92-7.354-10.623-7.354c-5.773,0-10.543,4.331-11.255,9.913H78.68v-5.83c0.184-1.584,0.724-3.076,1.606-4.436
				c0.173-0.268,0.36-0.526,0.556-0.779c5.664-7.32,20.504-8.848,24.307-8.848h34.701c0.795,0,1.44-0.645,1.44-1.44v-9.913
				c0-0.795-0.645-1.439-1.44-1.439s-1.44,0.645-1.44,1.439v8.474h-31.364c2.973-2.7,7.529-6.872,9.583-8.927
				c2.96-2.957,4.803-4.35,8.897-4.35h34.833l8.94,9.115c0.557,0.567,1.468,0.576,2.036,0.02s0.577-1.469,0.02-2.036
				L161.991,111.719z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M111.389,153.092h39.108c0.794,0,1.438-0.643,1.439-1.437c0-0.001,0-0.002,0-0.003
				c0-0.796-0.645-1.44-1.44-1.44h-39.108c-0.795,0-1.44,0.645-1.44,1.44c0,0.001,0,0.002,0,0.003
				C109.951,152.45,110.594,153.092,111.389,153.092z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M186.091,147.062v-18.179c0-0.795-0.645-1.439-1.44-1.439h-37.456c-0.795,0-1.44,0.645-1.44,1.439
				c0,0.796,0.645,1.44,1.44,1.44h36.016l0.001,8.848l0.001,7.802c-0.102,1.624-2.745,2.986-3.66,3.335
				c-0.578,0.217-0.93,0.765-0.93,1.348c0,0.168,0.025,0.339,0.087,0.506c0.217,0.578,0.766,0.935,1.348,0.935
				c0.168,0,0.339-0.029,0.506-0.092C181.106,152.801,185.854,150.914,186.091,147.062z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M166.241,143.183c3.511,0,6.697,2.204,7.928,5.485c0.216,0.576,0.765,0.928,1.345,0.93
				c0.169,0.001,0.342-0.024,0.509-0.088c0.745-0.279,1.122-1.109,0.842-1.854c-1.651-4.398-5.92-7.354-10.625-7.354
				c-6.261,0-11.355,5.093-11.355,11.353c0,6.263,5.094,11.356,11.355,11.356c4.71,0,8.98-2.963,10.625-7.372
				c0.278-0.745-0.101-1.575-0.846-1.853c-0.743-0.278-1.574,0.1-1.853,0.846c-1.227,3.289-4.413,5.499-7.927,5.499
				c-4.673,0-8.475-3.803-8.475-8.477C157.766,146.984,161.568,143.183,166.241,143.183z"
            />
            <path
              style={{ fill: theme.palette.iconColor.main }}
              d="M152.91,263.216c1.101,0.662,2.439,0.154,4.242-0.883c0.689-0.396,0.926-1.276,0.53-1.966
				c-0.396-0.69-1.277-0.928-1.966-0.53c-0.429,0.247-0.757,0.417-1.005,0.535c0.004-0.19,0.011-0.388,0.017-0.562
				c0.014-0.389,0.029-0.813,0.029-1.266c0-1.236-0.405-2.039-1.203-2.387c-1.377-0.597-2.521,0.465-4.8,2.586
				c-1.017,0.947-2.282,2.125-3.733,3.299c-1.703,1.38-3.87,1.878-5.764,1.735c2.887-1.664,5.841-3.946,6.786-6.364
				c0.519-1.328,0.425-2.634-0.272-3.776c-0.953-1.559-2.576-2.232-4.463-1.854c-3.88,0.784-8.325,5.867-8.81,9.086
				c-0.142,0.934,0.031,1.829,0.488,2.628c-0.013,0.003-0.026,0.007-0.039,0.011c-5.006,1.434-11.168,1.995-14.73-2.094
				c8.314-3.913,13.348-7.927,14.991-11.961c0.728-1.786,0.751-3.554,0.068-5.11c-1.215-2.774-5.971-2.077-9.674-0.638
				c-6.695,2.601-12.413,8.271-10.096,15.073c0.16,0.47,0.338,0.921,0.533,1.354c-0.682,0.293-1.393,0.589-2.132,0.887
				c-0.737,0.298-1.094,1.137-0.796,1.874c0.298,0.737,1.136,1.095,1.875,0.797c0.869-0.352,1.711-0.703,2.526-1.056
				c2.385,3.157,6.081,4.793,10.781,4.792c2.261,0,4.755-0.379,7.446-1.148c0.449-0.128,1.024-0.332,1.68-0.602
				c2.955,1.494,7.822,1.516,11.417-1.395c1.528-1.236,2.834-2.452,3.883-3.429c0.375-0.349,0.769-0.715,1.13-1.039
				C151.796,261.218,151.777,262.534,152.91,263.216z M116.23,257.85c-1.576-4.626,2.29-7.988,4.734-9.581
				c3.388-2.208,6.852-2.979,8.593-2.979c0.55,0,0.929,0.076,1.079,0.209c0.368,0.84,0.335,1.805-0.098,2.868
				c-0.817,2.007-3.729,5.856-13.875,10.59C116.508,258.608,116.363,258.24,116.23,257.85z M135.344,261.3
				c0.322-2.138,3.946-6.171,6.533-6.694c0.17-0.034,0.32-0.05,0.453-0.05c0.507,0,0.77,0.234,0.982,0.583
				c0.159,0.261,0.289,0.61,0.048,1.228c-0.822,2.102-4.649,4.561-7.643,6.015C135.451,262.083,135.28,261.722,135.344,261.3z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_23"></g>
      <g id="Layer_22"></g>
      <g id="Layer_21"></g>
      <g id="Layer_20"></g>
      <g id="Layer_19"></g>
      <g id="Layer_18"></g>
      <g id="Layer_17"></g>
      <g id="Layer_16"></g>
      <g id="Layer_15"></g>
      <g id="Layer_14"></g>
      <g id="Layer_13"></g>
      <g id="Layer_12"></g>
      <g id="Layer_11"></g>
      <g id="Layer_10"></g>
      <g id="Layer_9"></g>
      <g id="Layer_8"></g>
      <g id="Layer_7"></g>
      <g id="Layer_6"></g>
      <g id="Layer_5"></g>
      <g id="Layer_4"></g>
      <g id="Layer_3"></g>
    </SvgIcon>
  );
};

export default IconMyCases;
