import React from "react";
import * as guidebookDivorceContent from "../../../containers/Pages/Divorce/GuidebookDivorceOverview/GuidebookDivorceCardContent";
import CardLinked from "../../Cards/CardLinked/CardLinked";
import * as guidebookSettlementContent from "../../../containers/Pages/Settlement/GuidebookSettlementOverview/GuidebookSettlementCardContent";
import * as guidebookTrafficContent from "../../../containers/Pages/Traffic/GuidebookTrafficOverview/GuidebookTrafficCardContent";
import { AbstractCase } from "../../../types/Entities/AbstractCase";

type ServiceWorldCaseProductGuidebookBoxesProps = {
  product: AbstractCase;
};

const ServiceWorldCaseProductGuidebookBoxes = ({ product }: ServiceWorldCaseProductGuidebookBoxesProps) => {
  if (!product.isInitialProxy && product.isCustomerDataCompleted) {
    return null;
  }

  switch (product.name) {
    case "alimony":
      return null;
    case "divorce":
      return (
        <>
          <CardLinked
            linkTo={guidebookDivorceContent.guidebookDivorceCosts.linkTo}
            image={guidebookDivorceContent.guidebookDivorceCosts.image}
            headline={guidebookDivorceContent.guidebookDivorceCosts.headline}
            text={guidebookDivorceContent.guidebookDivorceCosts.text}
            altTitle={guidebookDivorceContent.guidebookDivorceCosts.altTitle}
          />
          <CardLinked
            linkTo={guidebookDivorceContent.guidebookDivorceRequirements.linkTo}
            image={guidebookDivorceContent.guidebookDivorceRequirements.image}
            headline={guidebookDivorceContent.guidebookDivorceRequirements.headline}
            text={guidebookDivorceContent.guidebookDivorceRequirements.text}
            altTitle={guidebookDivorceContent.guidebookDivorceRequirements.altTitle}
          />
        </>
      );
    case "death":
      return null;
    case "familyLawCase":
      return null;
    case "settlement":
      return (
        <>
          <CardLinked
            linkTo={guidebookSettlementContent.guidebookSettlementCalculation.linkTo}
            image={guidebookSettlementContent.guidebookSettlementCalculation.image}
            headline={guidebookSettlementContent.guidebookSettlementCalculation.headline}
            text={guidebookSettlementContent.guidebookSettlementCalculation.text}
            altTitle={guidebookSettlementContent.guidebookSettlementCalculation.altTitle}
          />
          <CardLinked
            linkTo={guidebookSettlementContent.guidebookSettlementProcedure.linkTo}
            image={guidebookSettlementContent.guidebookSettlementProcedure.image}
            headline={guidebookSettlementContent.guidebookSettlementProcedure.headline}
            text={guidebookSettlementContent.guidebookSettlementProcedure.text}
            altTitle={guidebookSettlementContent.guidebookSettlementProcedure.altTitle}
          />
        </>
      );
    case "traffic":
      return (
        <>
          <CardLinked
            linkTo={guidebookTrafficContent.guidebookTrafficAccusation.linkTo}
            image={guidebookTrafficContent.guidebookTrafficAccusation.image}
            headline={guidebookTrafficContent.guidebookTrafficAccusation.headline}
            text={guidebookTrafficContent.guidebookTrafficAccusation.text}
            altTitle={guidebookTrafficContent.guidebookTrafficAccusation.altTitle}
          />
          <CardLinked
            linkTo={guidebookTrafficContent.guidebookTrafficMinorOffence.linkTo}
            image={guidebookTrafficContent.guidebookTrafficMinorOffence.image}
            headline={guidebookTrafficContent.guidebookTrafficMinorOffence.headline}
            text={guidebookTrafficContent.guidebookTrafficMinorOffence.text}
            altTitle={guidebookTrafficContent.guidebookTrafficMinorOffence.altTitle}
          />
        </>
      );
    case "patientDecree":
    case "powerOfAttorney":
      return null;
    case "eventContract":
    case "membershipContract":
    case "otherContract":
    case "providerContract":
    case "purchaseContract":
    case "serviceContract":
    case "subscriptionContract":
    case "travelContract":
    case "vehicleContract":
    case "dataLeakContract":
      return null;
    default:
      return null;
  }
};

export default ServiceWorldCaseProductGuidebookBoxes;
